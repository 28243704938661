import * as React from 'react';

import { Box, Flex, Heading } from '@chakra-ui/react';

import { links } from '../../pages/main/data';

export const Footer: React.FC = () => {
  return (
    <>
      <Flex
        color='white'
        direction={{ base: 'column', md: 'row' }}
        gap='10px'
        justify='space-between'
        p={{ base: '30px 5%', xl: '30px 18%' }}
        sx={{ background: '#FD108A' }}
        w='100%'
      >
        <Box>
          <Heading size='md'>Want to stay informed?</Heading>
          <Box fontWeight='bold'>Subscribe to our newsletter right now</Box>
        </Box>

        <Flex>
          <Box
            p={{ base: '10px 30px 10px 10px', sm: '10px 200px 10px 10px' }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid white',
              // p: '10px 200px 10px 10px',
              fontWeight: 'bold',
            }}
          >
            ENTER EMAIL
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid white',
              p: '10px 50px',
              background: 'white',
              color: '#FD108A',
              fontWeight: 'bold',
            }}
          >
            SUBSCRIBE
          </Box>
        </Flex>
      </Flex>

      <Flex
        justify='space-between'
        p={{ base: '30px 5%', xl: '30px 15%' }}
        w='100%'
      >
        <Flex justify='space-between' w='800px'>
          {links.map((item) => {
            return (
              <Box key={item.id}>
                {item.title}
                <Box color='#6a6a6a' mt='15px'>
                  {item.links.map((item) => {
                    return <Box key={item}>{item}</Box>;
                  })}
                </Box>
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </>
  );
};
