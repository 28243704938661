import React, { useState } from 'react';

import { Box, Flex, Text, Switch } from '@chakra-ui/react';

interface ICustomSwitch {
  title: string;
  description: string;
  defaultValue: boolean;
  switchValue(type: boolean): void;
}

export const WidgetSwitch = ({
  title,
  description,
  defaultValue,
  switchValue,
}: ICustomSwitch) => {
  const [isChecked, setIsChecked] = useState(defaultValue);
  const changeValue = () => {
    setIsChecked(!isChecked);
    switchValue(!isChecked);
  };
  return (
    <Flex justifyContent='space-between' maxW='500px' mt='24px'>
      <Box>
        <Text color='app.black' fontSize='16px' fontWeight='700'>
          {title}
        </Text>
        <Text color='#757575' fontSize='13px' maxWidth='500px'>
          {description}
        </Text>
      </Box>
      <Switch
        isChecked={isChecked}
        ml='12px'
        onChange={changeValue}
        size='lg'
      />
    </Flex>
  );
};
