import React, { useState } from 'react';

import {
  InputGroup,
  Input,
  InputRightElement,
  Button,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

interface PasswordInputProps {
  id: string;
  name: string;
  placeholder?: string;
  isMobile?: boolean;
}

export const PasswordInput = ({
  id,
  placeholder,
  name,
  isMobile = false,
}: PasswordInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  return (
    <>
      <InputGroup size='md'>
        <Input
          id={id}
          placeholder={placeholder}
          pr='4.5rem'
          size={isMobile ? 'sm' : 'md'}
          type={show ? 'text' : 'password'}
          {...register(`${name}`)}
        />
        <InputRightElement height={['100%', '2.5rem']} width='4.5rem'>
          <Button
            fontSize={['sm', 'md']}
            h='1.75rem'
            onClick={handleClick}
            size={isMobile ? 'md' : 'sm'}
          >
            {show ? 'Hide' : 'Show'}
          </Button>
        </InputRightElement>
      </InputGroup>

      <FormErrorMessage fontSize={['xs', 'sm']}>
        {errors[name] && errors[name]?.message}
      </FormErrorMessage>
    </>
  );
};
