import React from 'react';

import { Text, Flex } from '@chakra-ui/react';

interface IComplete {
  merchantName: string;
  txnId: string;
  orderId: string;
}

export const Complete = ({ merchantName, txnId, orderId }: IComplete) => {
  return (
    <Flex
      alignItems={{ base: 'flex-start', md: 'center' }}
      direction='column'
      gap='10px'
      mt='30px'
    >
      <Text fontWeight='bold'>Thank you for shopping with {merchantName}</Text>
      <Text fontWeight='bold'>Your transaction ID is: {txnId}</Text>
      <Text fontWeight='bold'>Your order number is: {orderId}</Text>
      <Text fontWeight='bold'>
        We will email you when your goods are dispatched
      </Text>
    </Flex>
  );
};
