import * as React from 'react';

import {
  useNumberInput,
  Tr,
  Td,
  Flex,
  Image,
  Box,
  Heading,
  Button,
  Input,
  HStack,
  IconButton,
  Link,
} from '@chakra-ui/react';
import { FaTrashAlt } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

import { useAppDispatch } from '../../../../store/hooks';
import {
  changeProductQuantity,
  deleteProductById,
  IProductInfo,
  IProductUpdatedInfo,
} from '../../../../store/slices/basketSlice';
import { ButtonOverrideStyles } from '../../../../styles/Button';
import { Currency, Price } from '../../../CurrencyPrice';

interface IBasketProductCard {
  product: IProductInfo & IProductUpdatedInfo;
}

export const BasketProductCardLarge: React.FC<IBasketProductCard> = ({
  product,
}) => {
  const dispatch = useAppDispatch();

  const onQuantityChange = (value: string | number) => {
    dispatch(
      changeProductQuantity({
        id: product.id,
        quantity: Number(value),
      }),
    );
  };

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: product.quantity || 1,
      min: 1,
      max: 10,
      onChange: onQuantityChange,
    });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <Tr borderBottom='1px solid rgba(160,160,160,.25)'>
      <Td>
        <Flex
          sx={{
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: '10px 0',
            w: '285px',
          }}
        >
          <Link as={RouterLink} to={`/shoes/${product.id}`}>
            <Image height='77px' src={product.mainImage} w='77px' />
          </Link>
          <Box>
            <Heading as='h5' fontSize='17px'>
              {product.title}
            </Heading>
            <Box color='#797979' py='5px'>
              <Box as='span'>Size:</Box>
              <Box as='span' pl='5px'>
                43
              </Box>
            </Box>
          </Box>
        </Flex>
      </Td>

      <Td fontSize='20px' fontWeight='700' textAlign='center'>
        {Currency()}
        {Price(product.price)}
      </Td>

      <Td>
        <HStack justify='center' maxW='320px'>
          <Button
            {...dec}
            sx={{
              ...ButtonOverrideStyles,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '22px',
              marginLeft: '0 !important',
              padding: '0.25rem',
            }}
          >
            -
          </Button>
          <Input {...input} ml='0 !important' w='50px' />
          <Button
            {...inc}
            sx={{
              ...ButtonOverrideStyles,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '22px',
              marginLeft: '0 !important',
              padding: '0.25rem',
            }}
          >
            +
          </Button>
        </HStack>
      </Td>

      <Td fontSize='20px' fontWeight='700' textAlign='center'>
        {Currency()}
        {Price(product.totalPrice as string)}
      </Td>

      <Td textAlign='center'>
        <IconButton
          aria-label='trash'
          icon={<FaTrashAlt color='#FD108A' size='20' />}
          onClick={() => dispatch(deleteProductById(product.id))}
          sx={{ ...ButtonOverrideStyles }}
        />
      </Td>
    </Tr>
  );
};
