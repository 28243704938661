import React, { useEffect, useState } from 'react';

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Divider,
  Flex,
  Box,
  Heading,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  setIsBasketOpen,
  setProductsPrice,
} from '../../../store/slices/basketSlice';
import { ButtonOverrideStyles } from '../../../styles/Button';
import { PrimaryButton, SecondaryButton } from '../../Buttons';
import { Currency, Price } from '../../CurrencyPrice';
import { BasketProductCard } from '../basket-product-card';

export const BasketSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const { isBasketOpen, productsInBasket, shippingPrice, productsPrice } =
    useAppSelector((state) => state.basket);

  useEffect(() => {
    const quantityOfAll = productsInBasket.reduce(
      (acc, current) => acc + current.quantity,
      0,
    );
    const priceOfAll = productsInBasket.reduce(
      (acc, current) => acc + Number(current.totalPrice),
      0,
    );

    setTotalQuantity(quantityOfAll);
    dispatch(setProductsPrice(Number(priceOfAll.toFixed(2))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsInBasket]);

  const computedQuantityText = () => {
    return totalQuantity === 1
      ? `${totalQuantity} item`
      : `${totalQuantity} items`;
  };
  const computeTotalPrice = () => {
    return (productsPrice + shippingPrice).toFixed(2);
  };

  return (
    <>
      <Drawer
        isOpen={isBasketOpen}
        onClose={() => dispatch(setIsBasketOpen(false))}
        placement='right'
      >
        <DrawerOverlay />
        <DrawerContent maxW='340px'>
          <DrawerCloseButton sx={{ ...ButtonOverrideStyles }} top='16px' />
          <DrawerHeader color='#FD108A'>Basket</DrawerHeader>

          <Divider />

          <DrawerBody px='10px'>
            {productsInBasket.map((product, index: number) => (
              <BasketProductCard
                key={index}
                product={product}
                variant='small'
              />
            ))}
          </DrawerBody>

          <Divider />

          <DrawerFooter flexDirection={'column'} paddingTop='5px'>
            <Flex align='center' justify='space-between' w='100%'>
              <Box>{computedQuantityText()}</Box>
              <Box>
                {Currency()}
                {Price(productsPrice.toString())}
              </Box>
            </Flex>
            <Flex align='center' justify='space-between' w='100%'>
              <Box>Shipping</Box>
              <Box>
                {Currency()}
                {Price(shippingPrice.toString())}
              </Box>
            </Flex>

            <Divider pt='5px' />

            <Flex align='center' justify='space-between' pt='10px' w='100%'>
              <Flex align='baseline'>
                <Heading as='h3' color='#FD108A' fontSize='28px'>
                  Total
                </Heading>
                <Box fontSize='14px' pl='5px'>
                  (VAT incl.)
                </Box>
              </Flex>
              <Heading as='h3' fontSize='24px'>
                {Currency()}
                {Price(computeTotalPrice())}
              </Heading>
            </Flex>

            <Flex direction='column' mt='20px'>
              <PrimaryButton
                onClick={() => navigate('/checkout')}
                sx={{
                  marginBottom: '10px',
                  width: '200px',
                }}
              >
                Checkout
              </PrimaryButton>
              <SecondaryButton
                onClick={() => navigate('/basket')}
                sx={{ width: '200px' }}
              >
                Basket
              </SecondaryButton>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
