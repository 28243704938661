import React from 'react';

import {
  Box,
  Flex,
  Heading,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Radio,
  RadioGroup,
  Stack,
  Input,
} from '@chakra-ui/react';

import { PrimaryButton } from '../../components/Buttons';

export const PersonalInfoBlock: React.FC<any> = ({ setOpenedSection }) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton
          sx={{
            _focus: {
              boxShadow: 'none',
            },
          }}
        >
          <Box flex='1' textAlign='left'>
            <Heading color='#FD108A' my='20px' size='md'>
              1 Personal Information
            </Heading>
          </Box>
        </AccordionButton>
      </h2>
      <AccordionPanel borderTop='1px rgba(160,160,160,.25) solid' px='30px'>
        <Box>
          <Flex my='30px'>
            <Box w='150px'>Social title</Box>

            <RadioGroup>
              <Stack direction='row'>
                <Radio value='1'>Mr.</Radio>
                <Radio value='2'>Mrs.</Radio>
              </Stack>
            </RadioGroup>
          </Flex>

          <Flex
            align={{ base: 'flex-start', md: 'center' }}
            direction={{ base: 'column', md: 'row' }}
          >
            <Box w={{ base: 'fit-content', md: '150px' }}>First name</Box>

            <Box w={{ base: '100%', md: '60%' }}>
              <Input
                sx={{
                  border: 'none',
                  borderBottom: '1px solid black',
                  borderRadius: 0,
                  _focus: {
                    outline: 'none',
                  },
                }}
                type='text'
              />

              <Box fontSize='12px' mt='2px'>
                {
                  'Only letters and the dot (.) character, followed by a space, are allowed.'
                }
              </Box>
            </Box>
          </Flex>

          <Flex
            align={{ base: 'flex-start', md: 'center' }}
            direction={{ base: 'column', md: 'row' }}
            mt='20px'
          >
            <Box w={{ base: 'fit-content', md: '150px' }}>Last name</Box>

            <Box w={{ base: '100%', md: '60%' }}>
              <Input
                sx={{
                  border: 'none',
                  borderBottom: '1px solid black',
                  borderRadius: 0,
                  _focus: {
                    outline: 'none',
                  },
                }}
                type='text'
              />

              <Box fontSize='12px' mt='2px'>
                {
                  'Only letters and the dot (.) character, followed by a space, are allowed.'
                }
              </Box>
            </Box>
          </Flex>

          <Flex
            align={{ base: 'flex-start', md: 'center' }}
            direction={{ base: 'column', md: 'row' }}
            mt='20px'
          >
            <Box w={{ base: 'fit-content', md: '150px' }}>Email</Box>

            <Box w={{ base: '100%', md: '60%' }}>
              <Input
                sx={{
                  border: 'none',
                  borderBottom: '1px solid black',
                  borderRadius: 0,
                  _focus: {
                    outline: 'none',
                  },
                }}
                type='text'
              />
            </Box>
          </Flex>
        </Box>

        <Flex justifyContent='center'>
          <PrimaryButton
            onClick={() => setOpenedSection(1)}
            sx={{
              w: '200px',
              mt: '50px',
            }}
          >
            Continue
          </PrimaryButton>
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
};
