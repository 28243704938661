import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { BottlesPage } from './bottles';
import { CapsPage } from './caps';
import { CoastersPage } from './coasters';
import { HoodiesPage } from './hoodies';
import { MouseMatsPage } from './mouse-mats';
import { MugsPage } from './mugs';
import { PhoneCasesPage } from './phone-cases';
import { TShirtsPage } from './t-shirts';
import { UsbSticksPage } from './usb-sticks';

type TCategoryParam = {
  categoryName: string;
};

export const ProductsPage: React.FC = () => {
  const { categoryName } = useParams<TCategoryParam>();

  const chooseProductsPageByCategory = () => {
    switch (categoryName) {
      case 'bottles':
        return <BottlesPage />;
      case 'caps':
        return <CapsPage />;
      case 'coasters':
        return <CoastersPage />;
      case 'hoodies':
        return <HoodiesPage />;
      case 'mouse-mats':
        return <MouseMatsPage />;
      case 'mugs':
        return <MugsPage />;
      case 'phone-cases':
        return <PhoneCasesPage />;
      case 't-shirts':
        return <TShirtsPage />;
      case 'usb-sticks':
        return <UsbSticksPage />;
    }
  };

  return (
    <Flex align='center' direction='column' px='30px' w='100%'>
      <Box maxW='1340px' mt='120px' w='100%'>
        {chooseProductsPageByCategory()}
      </Box>
    </Flex>
  );
};
