import React, { useState } from 'react';

import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';

interface IWidgetInput {
  title: string;
  description: string;
  error: string;
  defaultValue: string;
  inputValue(type: string): void;
  type?: string;
}

export const WidgetInput = ({
  title,
  description,
  defaultValue,
  inputValue,
  type = 'text',
}: IWidgetInput) => {
  const [value, setValue] = useState(defaultValue);
  const handleClick = () => setValue('');
  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    inputValue(e.target.value);
  };

  return (
    <Box maxW='500px'>
      <Text color='app.black' fontSize='16px' fontWeight='700' mt='24px'>
        {title}
      </Text>
      <Text color='#757575' fontSize='13px'>
        {description}
      </Text>
      <InputGroup background='app.neutralGray' mt='12px'>
        <Input
          onChange={changeValue}
          placeholder=''
          pr='40px'
          sx={{
            _focus: {
              boxShadow: 'none',
            },
          }}
          type={type}
          value={value}
        />
        <InputRightElement width='2.5rem'>
          {value !== '' && (
            <Button h='14px' minW='14px' onClick={handleClick} p='0' size='sm'>
              <Image alt='cancel' src='/svgs/cancel.png' />
            </Button>
          )}
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};
