import React from 'react';

import { Box, Flex, Heading } from '@chakra-ui/react';

import { ProductCard } from '../../components/ProductCard';

import { allProducts } from './data';

export const MainPage: React.FC = () => {
  return (
    <Flex align='center' direction='column' px='30px' w='100%'>
      <Box maxW='1340px' mt='120px' w='100%'>
        <Heading color='#FD108A'>All products</Heading>

        <Box mt='30px'>
          <b>Remuno collection:</b> the best swag that money can buy.{' '}
          <b>100% cruelty free.</b>
        </Box>

        <Flex
          justify='space-between'
          mt='30px'
          py='10px'
          sx={{
            borderTop: '1px solid rgba(160,160,160,.25)',
            borderBottom: '1px solid rgba(160,160,160,.25)',
          }}
        >
          <Flex align='center'>
            <Box mr='10px'>{allProducts.length} product(s)</Box>
          </Flex>
        </Flex>

        <Flex
          gap='30px'
          justifyContent={{ base: 'space-around', md: 'flex-start' }}
          sx={{
            mt: '30px',
            textTransform: 'uppercase',
            fontSize: '18px',
          }}
          wrap='wrap'
        >
          {allProducts.map((item) => (
            <ProductCard item={item} key={item.id} />
          ))}
        </Flex>

        <Box my='70px'>
          <Box fontWeight='bold' mb='15px'>
            Unisex eco-friendly swag
          </Box>
          <b>Remuno swag</b> collection: buy it, wear it, put it on display. But
          above all, be <b>proud</b> of it.
        </Box>
      </Box>
    </Flex>
  );
};
