import React from 'react';

import { Box, Flex, Image, Text } from '@chakra-ui/react';

interface ILineStep {
  title: string;
  step: number;
  active: number;
}

export const LineStep = ({ title, step, active }: ILineStep) => {
  return (
    <Box textAlign='center' w='33%'>
      <Text>{title}</Text>
      <Flex alignItems='center' mt='10px' w='100%'>
        <Box bg='black' flex='1' h='2px'></Box>
        {step < active && (
          <Box h='20px' mx='4px' w='20px'>
            <Image alt='tick' src='/svgs/GSelected.svg' />
          </Box>
        )}
        {step === active && (
          <Flex
            alignItems='center'
            bg='black'
            borderRadius='20px'
            color='white'
            h='20px'
            justifyContent='center'
            mx='4px'
            w='20px'
          >
            {step + 1}
          </Flex>
        )}
        {step > active && (
          <Flex
            alignItems='center'
            bg='gray'
            borderRadius='20px'
            color='white'
            h='20px'
            justifyContent='center'
            mx='4px'
            w='20px'
          >
            {step + 1}
          </Flex>
        )}
        <Box bg='black' flex='1' h='2px'></Box>
      </Flex>
    </Box>
  );
};
