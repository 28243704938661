import React from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
} from '@chakra-ui/react';

import { IProductInfo } from '../../../store/slices/basketSlice';

interface IProduct {
  product: IProductInfo | null;
}

const accordionTitleStyles = {
  fontSize: '18px',
  fontWeight: '700',
  flex: '1',
  textAlign: 'left',
  padding: '10px 0',
};

export const ProductAccordion: React.FC<IProduct> = ({ product }) => {
  return (
    <Accordion allowMultiple>
      <AccordionItem>
        <Heading as='h2'>
          <AccordionButton
            sx={{
              _focus: {
                boxShadow: 'none',
              },
            }}
          >
            <Box sx={{ ...accordionTitleStyles }}>Description</Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel fontSize='18px' pb={4}>
          {product?.description ?? 'The best swag that money can buy.'}
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <Heading as='h2'>
          <AccordionButton
            sx={{
              _focus: {
                boxShadow: 'none',
              },
            }}
          >
            <Box sx={{ ...accordionTitleStyles }}>Product Details</Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel fontSize='18px' pb={4}>
          <Flex>
            <Box w='50%'>Durability</Box>
            <Box w='50%'>Lasts a lifetime</Box>
          </Flex>
          <Flex>
            <Box w='50%'>Swag Rating</Box>
            <Box w='50%'>A firm 5 stars</Box>
          </Flex>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <Heading as='h2'>
          <AccordionButton
            sx={{
              _focus: {
                boxShadow: 'none',
              },
            }}
          >
            <Box sx={{ ...accordionTitleStyles }}>Reviews (0)</Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel fontSize='18px' pb={4} />
      </AccordionItem>

      <AccordionItem>
        <Heading as='h2'>
          <AccordionButton
            sx={{
              _focus: {
                boxShadow: 'none',
              },
            }}
          >
            <Box sx={{ ...accordionTitleStyles }}>Social Influencers</Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel fontSize='18px' pb={4}>
          Are all over this.
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
