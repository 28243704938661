import React, { useState } from 'react';

import { Box, Flex, Image, Text } from '@chakra-ui/react';

import { CustomRadio } from '../form/radio';

interface ICustomDropdown {
  options: Array<any>;
  selectValue(type: number): void;
  value: number;
}

export const WidgetRadio = ({
  options,
  selectValue,
  value,
}: ICustomDropdown) => {
  const [active, setActive] = useState(value);
  return (
    <Box h='140px' overflow='auto'>
      <Box borderRadius='8px' mt='12px'>
        <Box>
          {options?.map((item, index) => (
            <Flex
              alignItems='center'
              backgroundColor='#F5F5F5'
              justifyContent='space-between'
              key={index}
              m='10px auto'
              maxW='400px'
              onClick={() => {
                setActive(index);
                selectValue(index);
              }}
              p='12px'
              w='100%'
            >
              <Flex alignItems='center'>
                <Box>
                  <Image alt='coin' src='/images/coin.png' w='30px' />
                </Box>
                <Text
                  fontSize='16px'
                  fontWeight='600'
                  lineHeight='19.2px'
                  maxW='450px'
                  ml='14px'
                >
                  {item?.displayName}
                </Text>
              </Flex>

              <CustomRadio selected={active === index ? true : false} />
            </Flex>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
