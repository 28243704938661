import React from 'react';

import { Box, Image, Text, Flex } from '@chakra-ui/react';

import { PrimaryButton } from '../Buttons';

interface IConfirm {
  quote: any;
  coin: number;
  next(): void;
  Price: string;
  disable: boolean;
}

export const Confirm = ({ quote, coin, next, Price, disable }: IConfirm) => {
  return (
    <Box mt='30px' textAlign='center'>
      <Text fontSize='lg'>Payment required</Text>
      <Flex alignItems='center' justifyContent='center' mb='10px'>
        <Text as='b' fontSize='28px'>
          {quote?.quote}
        </Text>
        <Image
          alt='coin logo'
          ml='6px'
          src={
            coin === 0
              ? 'icons/bnb.png'
              : coin === 2
              ? 'icons/ethereum.png'
              : 'icons/bitcoin.png'
          }
          width='22px'
        />
        <Text fontSize='17px' ml='4px'>
          {coin === 0 ? 'BNB' : coin === 2 ? 'ETH' : 'BITCOIN'}
        </Text>
      </Flex>
      <Flex alignItems='center' justifyContent='center'>
        <Text fontSize='lg'>Equivalent to</Text>
        <Text as='b' fontSize='lg' ml='10px'>
          {Price}
        </Text>
      </Flex>
      <Flex
        align='center'
        bottom='30px'
        justify='center'
        left='0'
        pos='absolute'
        w='100%'
      >
        <PrimaryButton
          disabled={disable}
          onClick={next}
          sx={{ w: '140px' }}
          type='submit'
        >
          Next
        </PrimaryButton>
      </Flex>
    </Box>
  );
};
