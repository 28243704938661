import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import { Options } from 'react-select';

import { SelectOption } from '../../utils/formatOptions';
import CustomSelect from '../Select';

interface ICustomDropdown {
  title: string;
  description: string;
  value: string | string[];
  error: string;
  options: Options<any>;
  selectValue: (value: any) => void;
  isMulti?: boolean;
}

export const WidgetSelect = ({
  title,
  description,
  value,
  options,
  selectValue,
  isMulti,
}: ICustomDropdown) => {
  return (
    <Box>
      <Text color='app.black' fontSize='16px' fontWeight='700' mt='24px'>
        {title}
      </Text>
      <Text color='#757575' fontSize='13px' maxW='500px'>
        {description}
      </Text>
      <Box backgroundColor='#F5F5F5' borderRadius='8px' maxW='500px' mt='12px'>
        <CustomSelect
          isMulti={isMulti}
          onChange={(val) =>
            isMulti
              ? selectValue(val.map((c: SelectOption) => c.value))
              : selectValue(val.value)
          }
          options={options}
          value={
            isMulti
              ? options?.filter((c) => value.includes(c.value))
              : options?.find((c) => c.value === value)
          }
        />
      </Box>
    </Box>
  );
};
