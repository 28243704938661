import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { BasketSidebar } from './components/Basket/basket-sidebar';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { RouteChangeWatcher } from './components/RouteChangeWatcher';
import { WidgetConfigModal } from './components/WidgetConfig';
import { BasketPage } from './pages/basket';
import { CheckoutPage } from './pages/checkout';
import { ForgotPasswordPage } from './pages/forgot-password';
import { LoginPage } from './pages/login';
import { MainPage } from './pages/main';
import { ProductPage } from './pages/product';
import { ProductsPage } from './pages/products';
import { RequestResetPasswordPage } from './pages/request-reset-password';
import { SignUpPage } from './pages/signup';
import { WidgetSettingsPage } from './pages/widget-settings';

function App() {
  return (
    <BrowserRouter>
      <Header />

      <BasketSidebar />
      <WidgetConfigModal />
      {/*{isLoggedIn && (*/}
      {/*  <Sidebar/>*/}
      {/*)}*/}

      <RouteChangeWatcher>
        <Routes>
          <Route element={<MainPage />} path='/' />
          <Route element={<CheckoutPage />} path='/checkout' />
          <Route element={<BasketPage />} path='/basket' />
          <Route element={<ProductsPage />} path='/:categoryName' />
          <Route element={<ProductPage />} path='/:categoryName/:productId' />

          <Route element={<SignUpPage />} path='/signup' />
          <Route element={<WidgetSettingsPage />} path='/widget-settings' />
          <Route element={<LoginPage />} path='/login' />
          <Route
            element={<RequestResetPasswordPage />}
            path='/request-reset-password'
          />
          <Route element={<ForgotPasswordPage />} path='/forgot-password' />
        </Routes>
      </RouteChangeWatcher>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
