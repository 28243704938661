import React, { useState } from 'react';

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useMediaQuery,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';

import { requestResetPasswordSchema } from '../validation';

type IChangeFormContent = {
  changeFormContent: Function;
};

export const RequestResetPasswordForm = ({
  changeFormContent,
}: IChangeFormContent) => {
  const [captchaValue, setCaptchaValue] = useState<string | null>('');
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const captchaKey = process.env.REACT_APP_RECAPTCHA_KEY || '';
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(requestResetPasswordSchema),
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = methods;

  const onReCAPTCHAChange = (value: string | null) => {
    // console.log('Captcha value:', value);
    setCaptchaValue(value);
  };

  const onSubmit = async (data: any) => {
    // eslint-disable-next-line no-console
    console.log({
      ...data,
      captchaValue,
    });
  };

  const handleClick = () => {
    changeFormContent(true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl h={['85px', '100px']} isInvalid={!!errors.emailAddress}>
        <FormLabel fontSize={['sm', 'md']} htmlFor='email-address'>
          Email Address:
        </FormLabel>
        <Input
          id='email-address'
          size={isMobile ? 'sm' : 'md'}
          type='email'
          {...register('emailAddress')}
        />
        <FormErrorMessage fontSize={['xs', 'sm']}>
          {errors.emailAddress && errors.emailAddress.message}
        </FormErrorMessage>
      </FormControl>

      {captchaKey && (
        <ReCAPTCHA onChange={onReCAPTCHAChange} sitekey={captchaKey} />
      )}

      <Flex justify='center' py='20px'>
        <Button
          colorScheme='teal'
          disabled={!isValid || !captchaValue}
          onClick={handleClick}
          type='submit'
          variant='solid'
        >
          Get the reset link
        </Button>
      </Flex>
    </form>
  );
};
