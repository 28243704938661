import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setIsBasketOpen } from '../../store/slices/basketSlice';

export const RouteChangeWatcher: React.FC = ({ children }) => {
  const { isBasketOpen } = useAppSelector((state) => state.basket);
  const dispatch = useAppDispatch();
  const location = useLocation();

  //Close basket sidebar during route change
  useEffect(() => {
    if (isBasketOpen) {
      dispatch(setIsBasketOpen(false));
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  //Scroll page to top during route change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return <>{children}</>;
};
