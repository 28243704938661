import React, { useState, useEffect } from 'react';

import { Box, Flex, Heading, Accordion } from '@chakra-ui/react';

import { useLazyMarkupQuery } from '../../services/CryptoService';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setOrderId, setMarkup } from '../../store/slices/widgetSlice';

import { AddressesBlock } from './AddressesBlock';
import { CheckoutSummary } from './CheckoutSummary';
import { PaymentBlock } from './PaymentBlock';
import { PersonalInfoBlock } from './PersonalInfoBlock';
import { ShipmentBlock } from './ShipmentBlock';

export const CheckoutPage: React.FC = () => {
  const [openedSection, setOpenedSection] = useState(0);
  const { merchantId, apiKey } = useAppSelector((state) => state.widget);
  const [getMarkup] = useLazyMarkupQuery();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (merchantId && apiKey) {
      const getMerchant = async () => {
        const markup = await getMarkup({
          merchantId: merchantId,
          price: 0,
        });

        dispatch(
          setMarkup({
            totalPrice: 0,
            merchantName: markup.data.merchantName,
          }),
        );
      };

      getMerchant();
      // generate random order number
      const orderID =
        '' +
        Math.floor(Math.random() * 10) +
        Math.floor(Math.random() * 10) +
        Math.floor(Math.random() * 10) +
        Math.floor(Math.random() * 10) +
        Math.floor(Math.random() * 10) +
        Math.floor(Math.random() * 10) +
        Math.floor(Math.random() * 10);
      dispatch(setOrderId(orderID));
    }
  }, [apiKey, dispatch, getMarkup, merchantId]);

  return (
    <Flex align='center' direction='column' px='30px' w='100%'>
      <Box maxW='1340px' mt='120px' w='100%'>
        <Heading color='#FD108A'>Checkout</Heading>

        <Flex justify='space-between'>
          <Box
            mb='50px'
            mt='30px'
            sx={{
              borderLeft: '1px rgba(160,160,160,.25) solid',
              borderRight: '1px rgba(160,160,160,.25) solid',
            }}
            w={{ base: '100%', xl: '65%' }}
          >
            <Accordion index={[openedSection]}>
              <PersonalInfoBlock setOpenedSection={setOpenedSection} />

              <AddressesBlock setOpenedSection={setOpenedSection} />

              <ShipmentBlock setOpenedSection={setOpenedSection} />

              <PaymentBlock setOpenedSection={setOpenedSection} />
            </Accordion>
          </Box>

          <CheckoutSummary />
        </Flex>
      </Box>
    </Flex>
  );
};
