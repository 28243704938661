import React, { useEffect, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { setProductsPrice } from '../../../store/slices/basketSlice';
import { PrimaryButton } from '../../Buttons';
import { Currency, Price } from '../../CurrencyPrice';

export const BasketSummary: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const { productsInBasket, shippingPrice, productsPrice } = useAppSelector(
    (state) => state.basket,
  );

  useEffect(() => {
    const quantityOfAll = productsInBasket.reduce(
      (acc, current) => acc + current.quantity,
      0,
    );
    const priceOfAll = productsInBasket.reduce(
      (acc, current) => acc + Number(current.totalPrice),
      0,
    );

    setTotalQuantity(quantityOfAll);
    dispatch(setProductsPrice(Number(priceOfAll.toFixed(2))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsInBasket]);

  const computedQuantityText = () => {
    return totalQuantity === 1
      ? `${totalQuantity} item`
      : `${totalQuantity} items`;
  };
  const computeTotalPrice = () => {
    return (productsPrice + shippingPrice).toFixed(2);
  };

  return (
    <Box
      flex={1}
      maxW='360px'
      sx={{
        borderLeft: '1px rgba(160,160,160,.25) solid',
        borderRight: '1px rgba(160,160,160,.25) solid',
        borderBottom: '1px rgba(160,160,160,.25) solid',
        height: 'fit-content',
      }}
    >
      <Box
        sx={{
          padding: '20px',
          fontSize: '16px',
          borderBottom: '1px solid rgba(160,160,160,.25)',
          borderTop: '1px solid rgba(160,160,160,.25)',
        }}
      >
        <Flex justify='space-between' mb='2px'>
          <Box>{computedQuantityText()}</Box>

          <Box>
            {Currency()}
            {Price(productsPrice.toString())}
          </Box>
        </Flex>

        <Flex justify='space-between'>
          <Box>Shipping</Box>

          <Box>
            {Currency()}
            {Price(shippingPrice.toString())}
          </Box>
        </Flex>
      </Box>

      <Flex
        borderBottom='1px solid rgba(160,160,160,.25)'
        fontSize='24px'
        fontWeight='bold'
        justify='space-between'
        p='20px'
      >
        <Box>Total (VAT incl.)</Box>
        <Box>
          {Currency()}
          {Price(computeTotalPrice())}
        </Box>
      </Flex>

      <Flex background='rgba(204,204,204,.15)' p='20px'>
        {/* <FaTag color='#FD108A' /> */}

        <Flex
          align='center'
          borderBottom='1px solid #FD108A'
          ml='10px'
          p='0 0 10px 10px'
          w='100%'
        >
          Promo code
        </Flex>

        <PrimaryButton sx={{ borderRadius: 20 }}>
          <Box>Add</Box>
        </PrimaryButton>
      </Flex>

      <Flex p='20px'>
        <PrimaryButton
          onClick={() => navigate('/checkout')}
          sx={{
            width: '100%',
            height: '100%',
            fontSize: '17px',
            // borderRadius: 'none',
            color: '#ffffff',
            padding: '15px 25px',
          }}
        >
          Proceed to checkout
        </PrimaryButton>
      </Flex>
    </Box>
  );
};
