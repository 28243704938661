/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Helmet from 'react-helmet';

import { useAppSelector } from '../../store/hooks';
import { WIDGET_SOURCE_BASE_DOMAIN } from '../../utils/constants';

interface WidgetButtonProps {
  handleSuccess: (txn: any) => void;
  onWidgetClose: () => void;
  onWidgetOpen: () => void;
}

export const WidgetButton: React.FC<WidgetButtonProps> = ({
  handleSuccess,
  onWidgetClose,
  onWidgetOpen,
}) => {
  const widgetParams = useAppSelector((state) => state.widget);
  const { shippingPrice, productsPrice } = useAppSelector(
    (state) => state.basket,
  );
  const { rateUSD, rateGBP } = useAppSelector((state) => state.currency);
  const [isScriptLoaded, setIsScriptLoaded] = useState(
    !!(window as any).Remuno?.Widget,
  );

  const currencyPrice = useCallback(
    (price: string | number | undefined) => {
      switch (widgetParams.fromFiat) {
        case 'USD':
          return Number((Number(price) * rateUSD).toFixed(2));
        case 'EUR':
          return Number(price);
        case 'GBP':
          return Number((Number(price) * rateGBP).toFixed(2));
        default:
          return Number(price);
      }
    },
    [widgetParams.fromFiat, rateGBP, rateUSD],
  );

  const callbacks = useMemo(() => {
    return {
      onTransactionCompleted: (data: any) => {
        console.log('TXN COMPLETED SUCCESS', data);
        if (data.txnStatus === 'received') {
          handleSuccess(data);
        }
      },
      onTransactionExpired: (data: any) =>
        console.log('TXN COMPLETED FAIL', data),
      onTransactionCreated: (data: any) =>
        console.log('TXN CREATED SUCCESS', data),
      onTransactionFailed: (data: any) => console.log('TXN CREATED FAIL', data),
      onQuoteUpdated: (data: any) => console.log('QUOTE UPDATED', data),
      onTransactionCancelled: (data: any) => console.log('TXN CANCELLED', data),
      onClose: () => {
        console.log('WIDGET CLOSED');
        onWidgetClose();
      },
      onOpen: () => {
        console.log('WIDGET OPENED');
        onWidgetOpen();
      },
    };
  }, [handleSuccess, onWidgetClose, onWidgetOpen]);

  const widgetConfig = useMemo(
    () => ({
      ...widgetParams,
      amount:
        productsPrice > 0 ? currencyPrice(productsPrice + shippingPrice) : 1,
      selector: '#widget-preview',
      callbacks,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widgetParams, productsPrice],
  );

  useEffect(() => {
    if (!(window as any).Remuno?.Widget) {
      (window as any).Remuno = {
        Widget: {
          onLoad: () => setIsScriptLoaded(true),
        },
      };
      return () => {
        (window as any).Remuno.Widget.destroy(widgetConfig);
        (window as any).Remuno.Widget.onLoad = undefined;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isScriptLoaded) {
      (window as any).Remuno.Widget.init(widgetConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScriptLoaded]);

  useEffect(() => {
    if (isScriptLoaded) {
      (window as any).Remuno.Widget.updateConfig(widgetConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetConfig]);

  return (
    <>
      <Helmet>
        <script
          defer
          src={`https://${WIDGET_SOURCE_BASE_DOMAIN}/widget.min.js`}
          type='text/javascript'
        />
      </Helmet>

      <div id='widget-preview' />
    </>
  );
};
