import React, { useState } from 'react';

import {
  Flex,
  Box,
  Heading,
  Divider,
  FormLabel,
  Input,
  FormErrorMessage,
  FormControl,
  useMediaQuery,
  Link,
  Button,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { FormContainer } from '../../components/form/form-container';
import { PasswordInput } from '../../components/form/inputs/PasswordInput';

import { signInSchema } from './validation';

export const LoginPage: React.FC = () => {
  const [captchaValue, setCaptchaValue] = useState<string | null>('');
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const captchaKey = process.env.REACT_APP_RECAPTCHA_KEY || '';
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(signInSchema),
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = methods;

  const onReCAPTCHAChange = (value: string | null) => {
    // console.log('Captcha value:', value);
    setCaptchaValue(value);
  };

  const onSubmit = async (data: any) => {
    // eslint-disable-next-line no-console
    console.log({
      ...data,
      captchaValue,
    });
  };

  return (
    <FormContainer>
      <Box pb='20px'>
        <Heading as='h1' color='#FD108A' size={isMobile ? 'md' : 'lg'}>
          Sign In
        </Heading>
        <Divider />
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            h={['85px', '100px']}
            isInvalid={Boolean(errors.emailAddress)}
          >
            <FormLabel fontSize={['sm', 'md']} htmlFor='email-address'>
              Email Address:
            </FormLabel>
            <Input
              id='email-address'
              size={isMobile ? 'sm' : 'md'}
              type='email'
              {...register('emailAddress')}
            />
            <FormErrorMessage fontSize={['xs', 'sm']}>
              {errors.emailAddress && errors.emailAddress.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            h={['85px', '100px']}
            isInvalid={Boolean(errors.password)}
          >
            <FormLabel fontSize={['sm', 'md']} htmlFor='password'>
              Password:
            </FormLabel>
            <PasswordInput id='password' isMobile={isMobile} name='password' />
          </FormControl>

          <Box fontSize={['sm', 'md']} pb='20px'>
            Forgot your password?&nbsp;
            <Link
              as={RouterLink}
              color='#64acff'
              textDecoration='underline'
              to='/request-reset-password'
            >
              Reset password
            </Link>
          </Box>

          {captchaKey && (
            <ReCAPTCHA onChange={onReCAPTCHAChange} sitekey={captchaKey} />
          )}

          <Flex justify='center' py='20px'>
            <Button
              colorScheme='teal'
              disabled={!isValid || !captchaValue}
              type='submit'
              variant='solid'
            >
              Sign In
            </Button>
          </Flex>

          <Divider />

          <Box fontSize={['sm', 'md']} pt='10px'>
            Not with us yet? Please,&nbsp;
            <Link
              as={RouterLink}
              color='#64acff'
              textDecoration='underline'
              to='/signup'
            >
              Create account
            </Link>
          </Box>
        </form>
      </FormProvider>
    </FormContainer>
  );
};
