import { useAppSelector } from '../../store/hooks';

export const Currency = () => {
  const { fromFiat } = useAppSelector((state) => state.widget);
  switch (fromFiat) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    default:
      return '$';
  }
};

export const Price = (price: string | number | undefined) => {
  const { fromFiat } = useAppSelector((state) => state.widget);
  const { rateUSD, rateGBP } = useAppSelector((state) => state.currency);
  switch (fromFiat) {
    case 'USD':
      return Number((Number(price) * rateUSD).toFixed(2));
    case 'EUR':
      return Number(price);
    case 'GBP':
      return Number((Number(price) * rateGBP).toFixed(2));
    default:
      return Number(price);
  }
};
