import React, { useEffect, useState } from 'react';

import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Image,
  Flex,
  IconButton,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react';
import { FaCog } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setIsBasketOpen } from '../../store/slices/basketSlice';

export const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const { productsInBasket } = useAppSelector((state) => state.basket);
  const [hamburger, setHamburger] = useState(true);
  const { open } = useAppSelector((state) => state.widget);
  const [setting, setSetting] = useState(false);
  const [cart, setCart] = useState(false);
  const [cartLock, setCartLock] = useState(false);

  const computeBasketTotalLength = () => {
    return productsInBasket.reduce((acc, product) => {
      return acc + product.quantity;
    }, 0);
  };

  const categoriesLinks = [
    { path: '/', label: 'All products' },
    { path: '/coasters', label: 'Coasters' },
    { path: '/mouse-mats', label: 'Mouse mats' },
    { path: '/bottles', label: 'Bottles' },
    { path: '/mugs', label: 'Mugs' },
    { path: '/caps', label: 'Caps' },
    { path: '/phone-cases', label: 'Phone cases' },
    { path: '/usb-sticks', label: 'USB sticks' },
    { path: '/t-shirts', label: 'T-shirts' },
    { path: '/hoodies', label: 'Hoodies' },
  ];

  const highLightCart = () => {
    if (cartLock) {
      setSetting(false);
      if (localStorage.getItem('cart') !== 'Right') {
        setCart(true);
        localStorage.setItem('cart', 'Right');
      }
    } else {
      setCartLock(true);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('setting') !== 'Right' && !open) {
      setSetting(true);
      localStorage.setItem('setting', 'Right');
    }
  }, [open]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        position: 'fixed',
        top: 0,
        boxShadow: '0 0 20px 0 rgb(0 0 0 / 5%)',
        zIndex: '1000',
        padding: '15px 30px',
      }}
      w='100%'
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        w='1340px'
      >
        <Flex align='center'>
          <Box>
            <Image
              alt='logo'
              marginRight='45px'
              src='/images/RemunoLogoColour.png'
              w='150px'
            />
          </Box>

          <Flex align='center' display={{ base: 'none', xl: 'flex' }} mb='-4px'>
            {categoriesLinks.map((category) => (
              <Link
                as={RouterLink}
                boxShadow='none'
                key={category.path}
                mr='25px'
                sx={{
                  _focus: {
                    boxShadow: 'none',
                  },
                }}
                to={category.path}
              >
                <Box>{category.label}</Box>
              </Link>
            ))}
          </Flex>
        </Flex>

        <Flex align='right' alignItems='center' fontSize='30px' w='140px'>
          {hamburger && (
            <HamburgerIcon
              // color='#FD108A'
              display={{ base: 'block', xl: 'none' }}
              mr='10px'
              onClick={() => setHamburger(false)}
            />
          )}
          {!hamburger && (
            <CloseIcon
              // color='#FD108A'
              display={{ base: 'block', xl: 'none' }}
              mr='20px'
              onClick={() => setHamburger(true)}
              w='20px'
            />
          )}
          <Popover isLazy isOpen={setting} onClose={highLightCart}>
            <PopoverTrigger>
              <Link as={RouterLink} to='widget-settings'>
                <IconButton
                  aria-label='cart'
                  icon={<FaCog color='#FD108A' />}
                  sx={{
                    fontSize: '28px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    _focus: {
                      boxShadow: 'none',
                    },
                  }}
                  variant='unstyled'
                />
              </Link>
            </PopoverTrigger>
            <PopoverContent background='#2745ac' color='white'>
              <PopoverHeader fontSize={18} fontWeight='semibold'>
                Demo Shop Settings
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody fontSize={14}>
                If you want to customize the appearance of the payment widget,
                or other aspects of the demo, please click the settings icon.
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Popover isLazy isOpen={cart} onClose={() => setCart(false)}>
            <PopoverTrigger>
              <Box position='relative'>
                <IconButton
                  aria-label='cart'
                  icon={<Image alt='cart' src='/images/cart.png' w='30px' />}
                  onClick={() => dispatch(setIsBasketOpen(true))}
                  sx={{
                    fontSize: '28px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    _focus: {
                      boxShadow: 'none',
                    },
                  }}
                  variant='unstyled'
                />
                {productsInBasket.length !== 0 && (
                  <Box
                    as='span'
                    sx={{
                      position: 'absolute',
                      width: '19px',
                      height: '19px',
                      fontSize: '12px',
                      fontWeight: '700',
                      textAlign: 'center',
                      borderRadius: '50%',
                      backgroundColor: '#014ba0',
                      color: '#ffffff',
                      top: '0',
                      right: '0',
                      zIndex: '99',
                    }}
                  >
                    {computeBasketTotalLength()}
                  </Box>
                )}
              </Box>
            </PopoverTrigger>
            <PopoverContent background='#2745ac' color='white'>
              <PopoverHeader fontSize={18} fontWeight='semibold'>
                Basket
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody fontSize={14}>
                Add as many items to your basket as you like and when you are
                ready, click here to checkout and demo the Remuno payment
                integration
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
        <Modal isOpen={!hamburger} onClose={() => setHamburger(true)}>
          <ModalOverlay />
          <ModalContent sx={{ w: '90%', pb: '20px' }}>
            <ModalHeader>Products</ModalHeader>
            <ModalBody>
              <Flex direction='column' gap='20px'>
                {categoriesLinks.map((category) => (
                  <Link
                    as={RouterLink}
                    boxShadow='none'
                    key={category.path}
                    mr='25px'
                    sx={{
                      _focus: {
                        boxShadow: 'none',
                      },
                    }}
                    to={category.path}
                  >
                    <Box onClick={() => setHamburger(true)}>
                      {category.label}
                    </Box>
                  </Link>
                ))}
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};
