import * as yup from 'yup';

import {
  isEmailFormatValid,
  isLowerCase,
  haveEightCharLength,
  isUpperCase,
  isContainSpecialSymbol,
  isContainNumber,
} from '../../utils/regexp/validations';

export const signInSchema = yup
  .object({
    emailAddress: yup.string().required().concat(isEmailFormatValid),
    password: yup
      .string()
      .required()
      .concat(isLowerCase)
      .concat(isUpperCase)
      .concat(isContainNumber)
      .concat(isContainSpecialSymbol)
      .concat(haveEightCharLength),
  })
  .required();
