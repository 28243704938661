import React from 'react';

import { Box, Text, Flex } from '@chakra-ui/react';

import { PrimaryButton } from '../Buttons';
import { WidgetRadio } from '../PaymentWidget/WidgetRadio';

interface IQuote {
  coins: any;
  disable: boolean;
  next(): void;
  Price: string;
  onSelect(type: number): void;
}

export const Quote = ({ coins, disable, next, Price, onSelect }: IQuote) => {
  return (
    <Box textAlign='center'>
      <Text fontSize='lg' mt='40px'>
        Amount you need to pay is
      </Text>
      <Text align='center' fontSize='3xl'>
        {Price}
      </Text>
      <Text fontSize='16px' fontWeight='bold' mt={{ base: '20px', md: '10px' }}>
        Select your payment coin
      </Text>
      <WidgetRadio
        options={coins}
        selectValue={(value) => onSelect(value)}
        value={-1}
      />
      <Flex
        align='center'
        bottom='30px'
        justify='center'
        left='0'
        pos='absolute'
        w='100%'
      >
        <PrimaryButton
          disabled={disable}
          onClick={next}
          sx={{ w: '140px' }}
          type='submit'
        >
          Next
        </PrimaryButton>
      </Flex>
    </Box>
  );
};
