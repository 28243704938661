import React from 'react';

import Select, { GroupBase, Props } from 'react-select';

function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  return (
    <Select
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'hotpink',
          primary: 'hotpink',
          primary50: 'hotpink',
          primary75: 'hotpink',
        },
      })}
      {...props}
    />
  );
}

export default CustomSelect;
