import * as React from 'react';

import { Box, Flex, Image, Text, Link, CSSObject } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  changeProductQuantity,
  IProductInfo,
  setIsBasketOpen,
  setProductInBasket,
} from '../../store/slices/basketSlice';
// import { PrimaryButton } from '../Buttons';
import { Currency, Price } from '../CurrencyPrice';

interface IProductCard {
  item: IProductInfo;
  sx?: CSSObject | undefined;
}
// type TCategoryParam = {
//   categoryName: string;
// };

export const ProductCard: React.FC<IProductCard> = ({ item, sx }) => {
  const dispatch = useAppDispatch();
  // const { categoryName } = useParams<TCategoryParam>();
  const { productsInBasket } = useAppSelector((state) => state.basket);
  // const { fromFiat } = useAppSelector((state) => state.widget);
  // const { rateEUR, rateGBP } = useAppSelector((state) => state.currency);

  const onMouseEnter = (e: any, item: any) => {
    if (e.target.childNodes[0]) {
      e.target.childNodes[0].src = item.hoverImage;
    }
  };

  const onMouseLeave = (e: any, item: any) => {
    if (e.target.childNodes[0]) {
      e.target.childNodes[0].src = item.mainImage;
    }
  };

  const addProductInBasket = (product: IProductInfo) => {
    const [matchById] = productsInBasket.filter(
      (item) => item.id === product.id,
    );

    if (matchById) {
      dispatch(
        changeProductQuantity({
          ...matchById,
          quantity: matchById.quantity + 1,
        }),
      );
    } else {
      dispatch(setProductInBasket(product));
    }
    dispatch(setIsBasketOpen(true));
  };

  return (
    <Box
      onMouseEnter={(e: React.MouseEvent) => onMouseEnter(e, item)}
      onMouseLeave={(e: React.MouseEvent) => onMouseLeave(e, item)}
      position='relative'
      sx={{
        p: '20px',
        w: '300px',
        _hover: {
          outline: 'none 1px #f2f2f2',
          boxShadow: '0 0 25px 0 rgb(0 0 0 / 15%)',
        },
        ...sx,
      }}
    >
      <Link as={RouterLink} to={`/${item.title}/${item.id}`}>
        <Image src={item.mainImage} />
      </Link>

      <Flex align='center' justify='space-between'>
        <Box>
          <Text>{item.title}</Text>
          <Text fontWeight='bold'>
            {Currency()}
            {Price(item.price)}
          </Text>
        </Box>
        <Image
          alt='cart'
          cursor='pointer'
          onClick={() => addProductInBasket(item)}
          src='/images/cart.png'
          w='28px'
        />
      </Flex>
    </Box>
  );
};
