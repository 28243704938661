import * as React from 'react';

import { Box, Image } from '@chakra-ui/react';

interface IRadio {
  selected: boolean;
}

export const CustomRadio = ({ selected }: IRadio) => {
  return (
    <Box>
      {selected ? (
        <Image alt='radio' src='/svgs/Selected.svg' />
      ) : (
        <Image alt='radio' src='/images/NotSelectedRadio.png' />
      )}
    </Box>
  );
};
