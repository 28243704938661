import React, { useState, useEffect, useRef } from 'react';

import {
  CircularProgress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  useToast,
  Flex,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Complete } from '../../components/PaymentModal/Complete';
import { Confirm } from '../../components/PaymentModal/Confirm';
import { Pending } from '../../components/PaymentModal/Pending';
import { Quote } from '../../components/PaymentModal/Quote';
import { LineStep } from '../../components/PaymentWidget/LineStep';
import {
  useCreateTransactionMutation,
  useLazyGetCoinsQuery,
  useLazyGetTransactionQuery,
  useLazyMarkupQuery,
  useLazyQuoteQuery,
} from '../../services/CryptoService';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  setSelectedCoin,
  setTotalPriceInCrypto,
  emptyProductBasket,
} from '../../store/slices/basketSlice';
import { setOrderId, setMarkup } from '../../store/slices/widgetSlice';

interface IModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface IBasketItem {
  quantity: number;
  totalPrice: string | number;
}

export const CustomPaymentModal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(0);
  const [qrCode, setQrCode] = useState('');
  const [coin, setCoin] = useState(0);
  const [quoteToken, setQuoteToken] = useState('');
  const [quote, setQuote] = useState<any>(null);
  const [coins, setCoins] = useState<any>();
  const [currentTime, setCurrentTime] = useState<number>(Date.now());
  const [coinSelected, setCoinSelected] = useState(true);
  const [transaction, setTransaction] = useState<any>(null);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [getCoinsTrigger] = useLazyGetCoinsQuery();
  const [quoteTrigger] = useLazyQuoteQuery();
  const [createTransaction] = useCreateTransactionMutation();
  const [getTransactionTrigger] = useLazyGetTransactionQuery();
  const [getMarkup] = useLazyMarkupQuery();
  const { totalPriceInCrypto, selectedCoin, shippingPrice, productsInBasket } =
    useAppSelector((state) => state.basket);
  const { orderDesc, orderId, fromFiat, merchantId, markup } = useAppSelector(
    (state) => state.widget,
  );
  const { rateUSD, rateGBP } = useAppSelector((state) => state.currency);
  const lineStep = ['Quote', 'Confirm', 'Pending', 'Complete'];

  const onSelectCrypto = async (value: number) => {
    setCoinSelected(false);
    setCoin(value);
    const crypto = coins[value];
    const { data }: any = await quoteTrigger({
      merchantId: merchantId,
      toCoin: crypto.code,
      fromFiat: fromFiat,
      amount: Price((totalPrice + shippingPrice).toString()),
    });
    setQuoteToken(data.token);
    setQuote(data.quote);
    dispatch(setSelectedCoin(crypto));
    dispatch(setTotalPriceInCrypto(parseFloat(data.quote)));
  };

  const nextStep = () => {
    const _status = status + 1;
    setStatus(_status);
  };

  const getCoins = async () => {
    const markup = await getMarkup({
      merchantId: merchantId,
      price: Price(totalPrice + shippingPrice),
    });
    dispatch(setMarkup(markup.data));
    const { data }: any = await getCoinsTrigger({
      merchantId: merchantId,
    });
    if (data) {
      const transformedCrypto = data?.map((item: any) => {
        return {
          ...item,
          value: item.id,
          label: item.code,
        };
      });
      setCoins(transformedCrypto);
    } else {
      toast({
        title: 'Your merchant Id or API Key is not valid!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
      closeModal();
    }
  };

  const Currency = () => {
    switch (fromFiat) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      default:
        return '$';
    }
  };

  const Price = (price: string | undefined | number) => {
    switch (fromFiat) {
      case 'USD':
        return Number((Number(price) * rateUSD).toFixed(2));
      case 'EUR':
        return Number(price);
      case 'GBP':
        return Number((Number(price) * rateGBP).toFixed(2));
      default:
        return Number(price);
    }
  };

  const closeModal = () => {
    clearInterval(timer.current!);
    onClose();
    if (status === 3) {
      dispatch(emptyProductBasket());
      navigate('/');
    }
  };

  const Loading = () => (
    <Flex justifyContent='center' mt='100px'>
      <CircularProgress color='#2745ac' isIndeterminate />
    </Flex>
  );

  const pay = async () => {
    setQrCode('');
    const { data }: any = await createTransaction({
      merchantId: merchantId,
      orderId: orderId,
      orderDesc: orderDesc,
      fiat: fromFiat,
      fiatAmount: markup?.totalPrice,
      network: selectedCoin.network,
      coin: selectedCoin.code,
      coinAmount: totalPriceInCrypto,
      createdAt: '2022-05-23T10:03:09.423Z',
      quoteToken: quoteToken,
    });
    setTransaction(data);
    setQrCode(
      `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${data.walletAddress}`,
    );
    setCurrentTime(Date.now());
    timer.current = setInterval(async () => {
      const txn: any = await getTransactionTrigger({
        orderId: data?.id,
        merchantId: merchantId,
      });
      if (txn.data?.txnStatus === 'received') {
        setTransaction(txn.data);
        clearInterval(timer.current!);
        nextStep();
      }
    }, 5000);
  };

  useEffect(() => {
    const priceOfAll = productsInBasket.reduce(
      (acc: number, current: IBasketItem) => acc + Number(current.totalPrice),
      0,
    );

    setTotalPrice(Number(priceOfAll.toFixed(2)));
  }, [productsInBasket]);

  useEffect(() => {
    setStatus(0);
    if (isOpen) {
      getCoins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (status === 2) {
      setCoinSelected(false);
      setOrderId(orderId);
      pay();
    } else if (status === 1) {
      setQrCode('');
    } else if (status === 0) {
      setQuote(null);
      setCoinSelected(true);
    }
    return () => {
      clearInterval(timer.current!);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  return (
    <Modal isCentered isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />

      <ModalContent
        sx={{
          w: { base: '350px', md: '750px' },
          minH: '500px',
          minW: { base: '320px', md: '750px' },
        }}
      >
        <ModalHeader>Remuno Shop</ModalHeader>
        <ModalCloseButton
          sx={{
            _focus: {
              boxShadow: 'none',
            },
          }}
        />

        <ModalBody>
          <Flex>
            {lineStep.map((item: string, index: number) => (
              <LineStep active={status} key={index} step={index} title={item} />
            ))}
          </Flex>
          {status === 0 &&
            (coins?.length > 0 ? (
              <Quote
                Price={`${Currency()}${Number(markup?.totalPrice)?.toFixed(2)}`}
                coins={coins}
                disable={coinSelected}
                next={nextStep}
                onSelect={onSelectCrypto}
              />
            ) : (
              <Loading />
            ))}
          {status === 1 &&
            (quote !== null ? (
              <Confirm
                Price={`${Currency()}${quote?.adjustedAmount}`}
                coin={coin}
                disable={coinSelected}
                next={nextStep}
                quote={quote}
              />
            ) : (
              <Loading />
            ))}
          {status === 2 &&
            (qrCode !== '' ? (
              <Pending
                coin={coin}
                getQuote={pay}
                quote={quote}
                src={qrCode}
                time={currentTime}
                timer={timer}
                txn={transaction}
              />
            ) : (
              <Loading />
            ))}
          {status === 3 && (
            <Complete
              merchantName={markup.merchantName}
              orderId={orderId}
              txnId={transaction?.id}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
