import React, { useEffect, useRef } from 'react';

import { Box, Text, Flex, Image } from '@chakra-ui/react';
import Countdown, { zeroPad } from 'react-countdown';

import { PrimaryButton } from '../Buttons';

interface IPending {
  txn: any;
  coin: number;
  quote: any;
  src: any;
  time: number;
  timer: any;
  getQuote(): void;
}

export const Pending = ({
  txn,
  coin,
  quote,
  src,
  time,
  timer,
  getQuote,
}: IPending) => {
  const timerRef = useRef<any>();
  const refreshQuote = () => {
    clearInterval(timer.current!);
    getQuote();
  };
  const Completionist = () => (
    <div>
      <p>Price guarantee expired</p>
      <PrimaryButton
        onClick={refreshQuote}
        sx={{
          w: '100px',
          h: '30px',
          p: 0,
          borderRadius: '20px',
        }}
      >
        Get Quote
      </PrimaryButton>
    </div>
  );
  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <div>
          <Text>Price guarantee expires in</Text>
          <span style={{ fontWeight: '700' }}>
            {zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        </div>
      );
    }
  };
  useEffect(() => {
    timerRef.current.start();
  }, [time]);
  return (
    <Box mt='40px'>
      <Flex justifyContent='space-between'>
        <Box bg='gray.100' borderRadius='10px' p='10px' w='45%'>
          <Text>Payment status</Text>
          <Text as='b'>{txn?.txnStatus}</Text>
        </Box>
        <Box bg='gray.300' borderRadius='10px' p='10px' w='45%'>
          <Countdown date={time + 900000} ref={timerRef} renderer={renderer} />
        </Box>
      </Flex>
      <Flex
        alignItems='center'
        bg='gray.100'
        borderRadius='10px'
        direction={{ base: 'column-reverse', md: 'row' }}
        justifyContent='space-between'
        mt='20px'
        p='10px'
      >
        <Box mt='10px' w={{ base: '100%', md: '65%' }}>
          <Flex alignItems='center' mb='10px'>
            <Text as='b' fontSize='28px'>
              {quote?.quote}
            </Text>
            <Image
              alt='coin logo'
              ml='6px'
              src={
                coin === 0
                  ? 'icons/bnb.png'
                  : coin === 2
                  ? 'icons/ethereum.png'
                  : 'icons/bitcoin.png'
              }
              width='22px'
            />
            <Text fontSize='17px' ml='4px'>
              {coin === 0 ? 'BNB' : coin === 2 ? 'ETH' : 'BITCOIN'}
            </Text>
          </Flex>
          <Text>Scan QR code</Text>
          <Text mt='10px'>OR</Text>
          <Text mt='10px'>Send to this address:</Text>
          <Text as='b'>{txn?.walletAddress}</Text>
        </Box>
        <Box>
          <Image alt='qr' src={src} w={{ base: '140px', md: '180px' }} />
        </Box>
      </Flex>
    </Box>
  );
};
