import React, { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  AccordionButton,
  AccordionItem,
  Accordion,
} from '@chakra-ui/react';

import { PrimaryButton } from '../../components/Buttons';
import { Currency, Price } from '../../components/CurrencyPrice';
import { useAppSelector } from '../../store/hooks';

export const CheckoutSummary: React.FC = () => {
  const {
    totalPriceInCrypto,
    selectedCoin,
    productsInBasket,
    shippingPrice,
    productsPrice,
  } = useAppSelector((state) => state.basket);
  const { orderId } = useAppSelector((state) => state.widget);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);

  useEffect(() => {
    const quantityOfAll = productsInBasket.reduce(
      (acc, current) => acc + current.quantity,
      0,
    );

    setTotalQuantity(quantityOfAll);
  }, [productsInBasket]);

  const computedQuantityText = () => {
    return totalQuantity === 1
      ? `${totalQuantity} item`
      : `${totalQuantity} items`;
  };
  const computeTotalPrice = () => {
    return (productsPrice + shippingPrice).toFixed(2);
  };

  return (
    <Box
      display={{ base: 'none', xl: 'block' }}
      mt='30px'
      sx={{
        borderLeft: '1px rgba(160,160,160,.25) solid',
        borderRight: '1px rgba(160,160,160,.25) solid',
        borderBottom: '1px rgba(160,160,160,.25) solid',
        height: 'fit-content',
      }}
      w='32%'
    >
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton
              sx={{
                _focus: {
                  boxShadow: 'none',
                },
              }}
            >
              <Box flex='1' textAlign='left'>
                {computedQuantityText()}
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>
      </Accordion>

      <Box
        borderBottom='1px solid rgba(160,160,160,.25)'
        fontSize='14px'
        p='20px'
      >
        <Flex justify='space-between' mb='2px'>
          <Box fontWeight='bold'>Order ID</Box>

          <Box fontWeight='bold'>{orderId}</Box>
        </Flex>
        <Flex justify='space-between' mb='2px'>
          <Box>Subtotal</Box>

          <Box>
            {Currency()}
            {Price(productsPrice.toString())}
          </Box>
        </Flex>

        <Flex justify='space-between'>
          <Box>Shipping</Box>

          <Box>
            {Currency()}
            {Price(shippingPrice.toString())}
          </Box>
        </Flex>
      </Box>

      <Flex
        borderBottom='1px solid rgba(160,160,160,.25)'
        fontSize='24px'
        fontWeight='bold'
        justify='space-between'
        p='20px'
      >
        <Box color='#FD108A'>Total (VAT incl.)</Box>

        <Box color='#FD108A'>
          {Currency()}
          {Price(computeTotalPrice())}
        </Box>
      </Flex>

      {totalPriceInCrypto ? (
        <Flex
          borderBottom='1px solid rgba(160,160,160,.25)'
          fontSize='24px'
          fontWeight='bold'
          justify='space-between'
          p='20px'
        >
          <Box>Total in crypto</Box>

          <Box>
            {totalPriceInCrypto} {selectedCoin.code}
          </Box>
        </Flex>
      ) : null}

      <Flex background='rgba(204,204,204,.15)' p='20px'>
        <Flex
          align='center'
          borderBottom='1px solid #FD108A'
          ml='10px'
          p='0 0 10px 10px'
          w='100%'
        >
          Promo code
        </Flex>
        <PrimaryButton sx={{ borderRadius: 20 }}>Add</PrimaryButton>
      </Flex>
    </Box>
  );
};
