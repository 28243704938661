/* eslint-disable no-console */
import React, { useMemo, useState } from 'react';

import {
  Box,
  Heading,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Image,
  Text,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from '../../components/Buttons';
import { WidgetButton } from '../../components/PaymentWidget/WidgetButton';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { emptyProductBasket } from '../../store/slices/basketSlice';

import { CustomPaymentModal } from './CustomPaymentModal';
import { WidgetResultModal } from './WidgetResultModal';

declare global {
  interface Window {
    txnSuccess: Function;
    txnFail: Function;
    createSuccess: Function;
    createFail: Function;
    widgetDeactivate: Function;
  }
}

export const PaymentBlock: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isWidgetOpen, setIsWidgetOpen] = useState<boolean>(false);
  const [txn, setTxn] = useState<any>(null);
  const { shippingPrice, productsPrice } = useAppSelector(
    (state) => state.basket,
  );
  const widget = useAppSelector((state) => state.widget);

  const customPayment = async () => {
    if (productsPrice + shippingPrice !== 0) {
      onOpen();
    } else {
      toast({
        title: 'You need to add items to your basket!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const closeModal = () => {
    dispatch(emptyProductBasket());
    navigate('/');
  };

  const handleSuccess = (transaction: any) => {
    setTxn(transaction);
  };

  const onWidgetOpen = () => {
    setIsWidgetOpen(true);
  };

  const onWidgetClose = () => {
    setIsWidgetOpen(false);
  };

  const isComplete = useMemo(
    () => !!(!isWidgetOpen && txn),
    [isWidgetOpen, txn],
  );

  return (
    <AccordionItem>
      <h2>
        <AccordionButton
          sx={{
            _focus: {
              boxShadow: 'none',
            },
          }}
        >
          <Box flex='1' textAlign='left'>
            <Heading color='#FD108A' my='20px' size='md'>
              4 Payment: Trial the Remuno prebuilt payment widget or
              customisable API
            </Heading>
          </Box>
        </AccordionButton>
      </h2>

      <AccordionPanel borderTop='1px rgba(160,160,160,.25) solid' px='30px'>
        <Flex direction='column' gap='20px'>
          <Flex
            alignItems='center'
            direction={{ base: 'column', lg: 'row' }}
            gap='20px'
            mt='20px'
          >
            <Flex
              background='#DDF1FD'
              borderRadius='4px'
              maxW='346px'
              p='10px'
              wrap='wrap'
            >
              <Box mt='5px'>
                <Image alt='info' src='/images/info1.png' />
              </Box>
              <Box flex='1' ml='10px'>
                <Text fontSize='13px'>
                  This demonstrates our payment widget which allows you to
                  integrate crypto payments into your website with a few lines
                  of HTML. This is the quickest option for giving your customers
                  the option of paying in crypto.
                </Text>
              </Box>
            </Flex>
            <Flex alignItems='center' gap='20px'>
              <WidgetButton
                handleSuccess={handleSuccess}
                onWidgetClose={onWidgetClose}
                onWidgetOpen={onWidgetOpen}
              />
              <Text display={{ base: 'none', lg: 'block' }}>Remuno Widget</Text>
            </Flex>
          </Flex>
          <Flex
            alignItems='center'
            direction={{ base: 'column', lg: 'row' }}
            gap='20px'
          >
            <Flex
              background='#DDF1FD'
              borderRadius='4px'
              maxW='346px'
              p='10px'
              wrap='wrap'
            >
              <Box mt='5px'>
                <Image alt='info' src='/images/info1.png' />
              </Box>
              <Box flex='1' ml='10px'>
                <Text fontSize='13px'>
                  This demonstrates custom integration with our payment API. If
                  you need complete control over the payment process, and have
                  access to development skills, then this is the option for you.
                </Text>
              </Box>
            </Flex>
            <Flex alignItems='center' gap='20px'>
              <PrimaryButton
                onClick={customPayment}
                sx={{
                  w: '170px',
                  p: 0,
                  borderRadius: '10px',
                }}
              >
                Checkout in Crypto
              </PrimaryButton>
              <Text display={{ base: 'none', lg: 'block' }}>
                Custom API integration
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </AccordionPanel>

      <CustomPaymentModal isOpen={isOpen} onClose={onClose} />
      <WidgetResultModal
        isOpen={isComplete}
        merchantName={widget.markup?.merchantName}
        onClose={closeModal}
        txn={txn}
      />
    </AccordionItem>
  );
};
