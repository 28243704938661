export type SelectOption = {
  label: string;
  value: any;
};

const formatOptions = (options: string[]): SelectOption[] =>
  options.map((opt) => ({
    value: opt,
    label: opt.charAt(0).toUpperCase() + opt.slice(1),
  }));
export default formatOptions;
