import { IProductInfo } from '../../store/slices/basketSlice';

export const products: IProductInfo[] = [
  {
    id: '41',
    title: 'grey',
    link: '/bottles/1',
    mainImage: '/images/grey-bottle-1.png',
    hoverImage: '/images/grey-bottle-1.png',
    largeImage: '/images/grey-bottle-1.png',
    price: '10',
  },
  {
    id: '24',
    title: 'Remuno mug 1',
    mainImage: '/images/mug-1.png',
    hoverImage: '',
    largeImage: '',
    price: '14.99',
  },
  {
    id: '12',
    title: 'Remuno coaster 4',
    mainImage: '/images/coaster-4.png',
    hoverImage: '',
    largeImage: '',
    price: '4',
  },
  {
    id: '6',
    title: 'white',
    mainImage: '/images/cap-white-1.png',
    hoverImage: '',
    largeImage: '',
    price: '30',
  },
  {
    id: '18',
    title: 'white',
    mainImage: '/images/hoodie-white-1.png',
    hoverImage: '',
    largeImage: '',
    price: '100',
  },
  {
    id: '22',
    title: 'Remuno mouse mat 2',
    mainImage: '/images/mouse-mat-2.png',
    hoverImage: '',
    largeImage: '',
    price: '7.5',
  },
  {
    id: '32',
    title: 'white',
    mainImage: '/images/phone-case-white-2.png',
    hoverImage: '',
    largeImage: '',
    price: '30',
  },
  {
    id: '39',
    title: 'Remuno T-shirt 6',
    mainImage: '/images/t-shirt-6.png',
    hoverImage: '',
    largeImage: '',
    price: '55',
  },
];

export const capsProducts = [
  {
    id: '1',
    title: 'black',
    mainImage: '/images/cap-black-1.png',
    hoverImage: '',
    largeImage: '',
    price: '25',
  },
  {
    id: '6',
    title: 'white',
    mainImage: '/images/cap-white-1.png',
    hoverImage: '',
    largeImage: '',
    price: '30',
  },
  {
    id: '4',
    title: 'pink',
    mainImage: '/images/cap-pink-1.png',
    hoverImage: '',
    largeImage: '',
    price: '35',
  },
];

export const coastersProducts = [
  {
    id: '9',
    title: 'Remuno coaster 1',
    mainImage: '/images/coaster-1.png',
    hoverImage: '',
    largeImage: '',
    price: '0.25',
  },
  {
    id: '10',
    title: 'Remuno coaster 2',
    mainImage: '/images/coaster-2.png',
    hoverImage: '',
    largeImage: '',
    price: '2',
  },
  {
    id: '11',
    title: 'Remuno coaster 3',
    mainImage: '/images/coaster-3.png',
    hoverImage: '',
    largeImage: '',
    price: '3',
  },
  {
    id: '12',
    title: 'Remuno coaster 4',
    mainImage: '/images/coaster-4.png',
    hoverImage: '',
    largeImage: '',
    price: '4',
  },
];

export const hoodiesProducts = [
  {
    id: '13',
    title: 'black',
    mainImage: '/images/hoodie-black-1.png',
    hoverImage: '',
    largeImage: '',
    price: '75',
  },
  {
    id: '18',
    title: 'white',
    mainImage: '/images/hoodie-white-1.png',
    hoverImage: '',
    largeImage: '',
    price: '100',
  },
];

export const mouseMatsProducts = [
  {
    id: '21',
    title: 'Remuno mouse mat 1',
    mainImage: '/images/mouse-mat-1.png',
    hoverImage: '',
    largeImage: '',
    price: '5',
  },
  {
    id: '22',
    title: 'Remuno mouse mat 2',
    mainImage: '/images/mouse-mat-2.png',
    hoverImage: '',
    largeImage: '',
    price: '7.5',
  },
  {
    id: '23',
    title: 'Remuno mouse mat 3',
    mainImage: '/images/mouse-mat-3.png',
    hoverImage: '',
    largeImage: '',
    price: '10',
  },
];

export const mugsProducts = [
  {
    id: '24',
    title: 'Remuno mug 1',
    mainImage: '/images/mug-1.png',
    hoverImage: '',
    largeImage: '',
    price: '14.99',
  },
  {
    id: '25',
    title: 'Remuno mug 2',
    mainImage: '/images/mug-2.png',
    hoverImage: '',
    largeImage: '',
    price: '19.99',
  },
];

export const phoneCasesProducts = [
  {
    id: '26',
    title: 'black',
    mainImage: '/images/phone-case-black-1.png',
    hoverImage: '',
    largeImage: '',
    price: '25',
  },
  {
    id: '32',
    title: 'white',
    mainImage: '/images/phone-case-white-2.png',
    hoverImage: '',
    largeImage: '',
    price: '30',
  },
  {
    id: '29',
    title: 'pink',
    mainImage: '/images/phone-case-pink-1.png',
    hoverImage: '',
    largeImage: '',
    price: '35',
  },
];

export const TShirtsProducts = [
  {
    id: '34',
    title: 'Remuno T-shirt 1',
    mainImage: '/images/t-shirt-1.png',
    hoverImage: '',
    largeImage: '',
    price: '45',
  },
  {
    id: '35',
    title: 'Remuno T-shirt 2',
    mainImage: '/images/t-shirt-2.png',
    hoverImage: '',
    largeImage: '',
    price: '45',
  },
  {
    id: '36',
    title: 'Remuno T-shirt 3',
    mainImage: '/images/t-shirt-3.png',
    hoverImage: '',
    largeImage: '',
    price: '50',
  },
  {
    id: '37',
    title: 'Remuno T-shirt 4',
    mainImage: '/images/t-shirt-4.png',
    hoverImage: '',
    largeImage: '',
    price: '50',
  },
  {
    id: '38',
    title: 'Remuno T-shirt 5',
    mainImage: '/images/t-shirt-5.png',
    hoverImage: '',
    largeImage: '',
    price: '55',
  },
  {
    id: '39',
    title: 'Remuno T-shirt 6',
    mainImage: '/images/t-shirt-6.png',
    hoverImage: '',
    largeImage: '',
    price: '55',
  },
];

export const USBSticksProducts = [
  {
    id: '40',
    title: 'Remuno USB stick 1',
    mainImage: '/images/usb.png',
    hoverImage: '',
    largeImage: '',
    price: '39.99',
  },
];

export const bottlesProducts = [
  {
    id: '41',
    title: 'grey',
    mainImage: '/images/grey-bottle-1.png',
    hoverImage: '',
    largeImage: '',
    price: '10',
  },
  {
    id: '48',
    title: 'white',
    mainImage: '/images/white-bottle-1.png',
    hoverImage: '',
    largeImage: '',
    price: '15',
  },
  {
    id: '45',
    title: 'pink',
    mainImage: '/images/pink-bottle-1.png',
    hoverImage: '',
    largeImage: '',
    price: '20',
  },
];

export const allProducts = [
  ...capsProducts,
  ...coastersProducts,
  ...bottlesProducts,
  ...hoodiesProducts,
  ...mouseMatsProducts,
  ...mugsProducts,
  ...phoneCasesProducts,
  ...TShirtsProducts,
  ...USBSticksProducts,
];

export const productsInSameCategory: IProductInfo[] = [
  {
    id: '1',
    title: 'marathon sigma dark red',
    link: '/shoes/1',
    mainImage: '',
    hoverImage: '',
    largeImage: '',
    price: '53.72',
  },
  {
    id: '2',
    title: 'marathon beyond ochre',
    link: '/shoes/2',
    mainImage: '',
    hoverImage: '',
    largeImage: '',
    price: '65.00',
  },
];

export const links = [
  {
    id: '1',
    title: 'Remuno',
    links: ['About us', 'Contact us'],
  },
  {
    id: '2',
    title: 'Legal',
    links: ['Privacy policy', 'Terms & conditions'],
  },
  {
    id: '3',
    title: 'Buyers guide',
    links: ['Shipping', 'Returns', 'FAQ'],
  },
];
