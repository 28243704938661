import { createSlice } from '@reduxjs/toolkit';

interface IInitialState {
  isBasketOpen: boolean;
  productsInBasket: IProductUpdatedInfo[];
  totalPrice: number;
  productsPrice: number;
  shippingPrice: number;
  totalPriceInCrypto: number;
  selectedCoin: any;
}

export interface IProductInfo {
  id: string;
  title: string;
  link?: string;
  description?: string;
  mainImage: string;
  hoverImage?: string;
  largeImage: string;
  price: string;
}
export interface IProductUpdatedInfo extends IProductInfo {
  quantity: number;
  totalPrice: number | string;
  isBasketOpen: boolean;
  totalPriceInCrypto: number;
  selectedCoin: any;
}

const initialState: IInitialState = {
  isBasketOpen: false,
  productsInBasket: [],
  totalPrice: 0,
  productsPrice: 0,
  shippingPrice: 0,
  totalPriceInCrypto: 0,
  selectedCoin: '',
};

const basketSlice = createSlice({
  name: 'basketSlice',
  initialState,
  reducers: {
    setIsBasketOpen(state, action) {
      state.isBasketOpen = action.payload;
    },
    setShippingPrice(state, action) {
      state.shippingPrice = action.payload;
    },
    setProductsPrice(state, action) {
      state.productsPrice = action.payload;
    },
    setTotalPriceInCrypto(state, action) {
      state.totalPriceInCrypto = action.payload;
    },
    setSelectedCoin(state, action) {
      state.selectedCoin = action.payload;
    },
    setProductInBasket(state, action) {
      state.productsInBasket.push({
        ...action.payload,
        quantity: 1,
        totalPrice: action.payload.price,
      });
    },
    emptyProductBasket(state) {
      state.productsInBasket = [];
    },
    deleteProductById(state, action) {
      state.productsInBasket = state.productsInBasket.filter(
        (product) => product.id !== action.payload,
      );
    },
    changeProductQuantity(state, action) {
      state.productsInBasket = state.productsInBasket.map((product) => {
        if (product.id === action.payload.id) {
          return {
            ...product,
            quantity: action.payload.quantity,
            totalPrice: (
              Number(product.price) * action.payload.quantity
            ).toFixed(2),
          };
        }
        return product;
      });
    },
  },
});

export default basketSlice.reducer;
export const { setIsBasketOpen } = basketSlice.actions;
export const { setShippingPrice } = basketSlice.actions;
export const { setProductsPrice } = basketSlice.actions;
export const { setProductInBasket } = basketSlice.actions;
export const { emptyProductBasket } = basketSlice.actions;
export const { deleteProductById } = basketSlice.actions;
export const { changeProductQuantity } = basketSlice.actions;
export const { setTotalPriceInCrypto } = basketSlice.actions;
export const { setSelectedCoin } = basketSlice.actions;
