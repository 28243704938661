import React from 'react';

import 'swiper/css';
import 'swiper/css/navigation';

import { IProductInfo } from '../../../store/slices/basketSlice';

interface ICarouselSameCategory {
  products: IProductInfo[];
}

export const CarouselSameCategory: React.FC<ICarouselSameCategory> = ({}) => {
  return <></>;
};
