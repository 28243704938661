import * as yup from 'yup';

import {
  isLowerCase,
  haveEightCharLength,
  isUpperCase,
  isContainSpecialSymbol,
  isContainNumber,
} from '../../utils/regexp/validations';

export const forgotPasswordSchema = yup
  .object({
    newPassword: yup
      .string()
      .required()
      .concat(isLowerCase)
      .concat(isUpperCase)
      .concat(isContainNumber)
      .concat(isContainSpecialSymbol)
      .concat(haveEightCharLength),
    confPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      .concat(isLowerCase)
      .concat(isUpperCase)
      .concat(isContainNumber)
      .concat(isContainSpecialSymbol)
      .concat(haveEightCharLength),
  })
  .required();
