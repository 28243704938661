import React from 'react';

import {
  IProductInfo,
  IProductUpdatedInfo,
} from '../../../store/slices/basketSlice';

import { BasketProductCardLarge } from './large';
import { BasketProductCardSmall } from './small';

interface IBasketProductCard {
  variant: 'large' | 'small';
  product: IProductInfo & IProductUpdatedInfo;
}

export const BasketProductCard: React.FC<IBasketProductCard> = ({
  variant,
  product,
}) => {
  return variant === 'small' ? (
    <BasketProductCardSmall product={product} />
  ) : (
    <BasketProductCardLarge product={product} />
  );
};
