import React, { useEffect } from 'react';

import {
  Button,
  Box,
  Image,
  Flex,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setRate } from '../../store/slices/currencySlice';
import {
  setOpen,
  setApiKey,
  setMerchantId,
  setFromFiat,
} from '../../store/slices/widgetSlice';
import { WidgetInput } from '../PaymentWidget/WidgetInput';

export const WidgetConfigModal = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const _apiKey = searchParams.get('apiKey');
  const _merchantId = searchParams.get('merchantId');
  const _fiat = searchParams.get('fiat');
  const toast = useToast();
  const { open, apiKey, merchantId } = useAppSelector((state) => state.widget);
  const acceptInput = () => {
    if (apiKey !== '' && merchantId != '') {
      dispatch(setOpen(false));
    } else {
      toast({
        title: 'You need to enter API Key and Merchant Id values',
        status: 'error',
        isClosable: true,
      });
    }
  };
  const setApiKeyValue = (value: string) => {
    if (value !== '') {
      dispatch(setApiKey(value));
    }
  };
  const setMerchantIdValue = (value: string) => {
    if (value !== '') {
      dispatch(setMerchantId(value));
    }
  };
  const getRate = async () => {
    const config: any = {
      method: 'get',
      url: 'https://api.exchangerate.host/latest?base=EUR',
    };
    const res = await axios(config);
    dispatch(
      setRate({
        gbp: res.data.rates.GBP,
        usd: res.data.rates.USD,
      }),
    );
  };
  useEffect(() => {
    if (_apiKey) {
      dispatch(setApiKey(_apiKey));
    } else if (localStorage.getItem('apiKey')) {
      dispatch(setApiKey(localStorage.getItem('apiKey')));
    }
    if (_merchantId) {
      dispatch(setMerchantId(_merchantId));
    } else if (localStorage.getItem('merchantId')) {
      dispatch(setMerchantId(localStorage.getItem('merchantId')));
    }
    if (_fiat) {
      dispatch(setFromFiat(_fiat.replace('/', '')));
    }
    getRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal isCentered isOpen={open} onClose={acceptInput}>
      <ModalOverlay />

      <ModalContent sx={{ m: '10px' }}>
        <ModalBody p='20px'>
          <Flex
            background='#DDF1FD'
            borderRadius='4px'
            maxW='546px'
            p='10px'
            wrap='wrap'
          >
            <Box mt='5px'>
              <Image alt='info' src='/images/info1.png' />
            </Box>
            <Box flex='1' ml='10px'>
              <Text fontSize={{ base: '12px', lg: '16px' }}>
                Welcome to the Remuno Shop demo site. Here you can see the
                payment widget and custom API integration in action when you
                checkout. This site is provided for demonstration purposes only,
                though you can pay yourself if you really want to!
              </Text>
            </Box>
          </Flex>
          <WidgetInput
            defaultValue={apiKey}
            description=''
            error='Please enter API key'
            inputValue={(value) => setApiKeyValue(value)}
            title='API Key'
          />
          <WidgetInput
            defaultValue={merchantId}
            description=''
            error='Please enter Merchant Id'
            inputValue={(value) => setMerchantIdValue(value)}
            title='Merchant Id'
          />
        </ModalBody>
        <ModalFooter>
          <a href='https://remuno.com'>
            <Button colorScheme='blue' variant='outline'>
              Return to Remuno
            </Button>
          </a>
          <Button colorScheme='blue' ml='10px' onClick={acceptInput}>
            Accept
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
