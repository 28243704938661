import React, { useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  Text,
} from '@chakra-ui/react';

interface ICheckboxInput {
  title: string;
  description: string;
  error: string;
  defaultValue: string;
  defaultChecked: boolean;
  toggle(type: boolean): void;
  inputValue(type: string): void;
  type?: string;
}

export const CheckboxInput = ({
  title,
  description,
  defaultValue,
  defaultChecked,
  toggle,
  inputValue,
  type = 'text',
}: ICheckboxInput) => {
  const [value, setValue] = useState(defaultValue);
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const handleClick = () => setValue('');
  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    inputValue(e.target.value);
  };
  const toggleSwitch = () => {
    setIsChecked(!isChecked);
    toggle(!isChecked);
  };

  return (
    <Box maxW='500px'>
      <Flex justifyContent={'space-between'}>
        <Text color='app.black' fontSize='16px' fontWeight='700' mt='24px'>
          {title}
        </Text>
        <Switch
          isChecked={isChecked}
          m='20px 0 0 12px'
          onChange={() => toggleSwitch()}
          size='lg'
        />
      </Flex>
      <Text color='#757575' fontSize='13px' mt='10px'>
        {description}
      </Text>
      <InputGroup background='app.neutralGray' mt='12px'>
        <Input
          onChange={changeValue}
          placeholder=''
          pr='40px'
          sx={{
            _focus: {
              boxShadow: 'none',
            },
          }}
          type={type}
          value={value}
        />
        <InputRightElement width='2.5rem'>
          {value !== '' && (
            <Button h='14px' minW='14px' onClick={handleClick} p='0' size='sm'>
              <Image alt='cancel' src='/svgs/cancel.png' />
            </Button>
          )}
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};
