import React from 'react';

import { Heading, useMediaQuery } from '@chakra-ui/react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import { IProductInfo } from '../../../store/slices/basketSlice';
import { ProductCard } from '../../ProductCard';

interface ICarouselAlsoBuy {
  products: IProductInfo[];
}

export const CarouselAlsoBuy: React.FC<ICarouselAlsoBuy> = ({ products }) => {
  const [isFour] = useMediaQuery('(max-width: 1400px)');
  const [isThree] = useMediaQuery('(max-width: 1020px)');
  const [isTwo] = useMediaQuery('(max-width: 694px)');
  return (
    <>
      <Heading
        color='#FD108A'
        fontSize='25px'
        m={{ base: '0 0 30px 0', lg: '0 0 40px 0' }}
        textAlign='left'
      >
        See what social influencers are buying:
      </Heading>

      <Swiper
        centeredSlides={isTwo ? true : false}
        className='alsoBuy-carousel'
        modules={[Navigation]}
        navigation
        onSlideChange={() => {
          // eslint-disable-next-line no-console
          console.log('slide change');
        }}
        onSwiper={(swiper) => {
          // eslint-disable-next-line no-console
          console.log(swiper);
        }}
        slidesPerView={isTwo ? 1 : isThree ? 2 : isFour ? 3 : 4}
        spaceBetween={30}
      >
        {products.map((product) => (
          <SwiperSlide key={product.id}>
            <ProductCard item={product} sx={{ m: 'auto', w: '260px' }} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
