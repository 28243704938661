import * as yup from 'yup';

export const isLowerCase = yup
  .string()
  .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase');
export const isUpperCase = yup
  .string()
  .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase');
export const isContainNumber = yup
  .string()
  .matches(/^(?=.*[0-9])/, 'Must contain at least one number');
export const isContainSpecialSymbol = yup
  .string()
  .matches(/^(?=.*[!@#$%^&*])/, 'Must contain at least one special symbol');
export const haveEightCharLength = yup
  .string()
  .matches(/^.{8,20}$/, 'Must contain from 8 to 20 characters');
export const isEmailFormatValid = yup
  .string()
  .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email format');
