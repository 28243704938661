import React from 'react';

import { Button, CSSObject } from '@chakra-ui/react';

interface IButtonProps {
  onClick?(): void;
  sx?: CSSObject | undefined;
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  as?: any;
}

interface IScrollLink {
  to?: string;
  spy?: boolean;
  smooth?: boolean;
  duration?: number;
}

const commonButtonStyles = {
  // h: '46px',
  borderRadius: '100px',
  fontWeight: '600',
  // fontSize: '20px',
  outline: 'none',
  cursor: 'pointer',
  _focus: {
    outline: 'none',
  },
};

export const PrimaryButton: React.FC<IButtonProps & IScrollLink> = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Button
      sx={{
        ...commonButtonStyles,
        background: '#FD108A',
        border: '2px solid',
        borderColor: '#FD108A',
        color: '#FFFFFF',
        position: 'relative',
        overflow: 'hidden',
        transition: '.5s',
        _before: {
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '180%',
          background: '#FD108A',
          borderRadius: '0 0 50% 50%',
          zIndex: '-1',
          transition: '.5s',
        },
        _hover: {
          background: 'transparent',
          border: '2px solid',
          borderColor: '#000000',
          color: '#000000',
          _before: {
            height: '0%',
          },
          _disabled: {
            background: '#FD108A',
            border: '2px solid',
            borderColor: '#FD108A',
            color: '#FFFFFF',
          },
        },
        _active: {
          background: 'transparent',
          border: '2px solid',
          borderColor: '#000000',
          color: '#000000',
          _before: {
            background: 'transparent',
          },
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export const SecondaryButton: React.FC<IButtonProps & IScrollLink> = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Button
      px='40px'
      sx={{
        ...commonButtonStyles,
        background: 'white',
        color: '#FD108A',
        position: 'relative',
        overflow: 'hidden',
        transition: '.5s',
        boxShadow: '4px 3px 19px 1px rgba(0, 0, 0, 0.07)',
        border: '2px solid',
        transform: 'scale(1)',
        _before: {
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '180%',
          background: 'white',
          borderRadius: '0 0 50% 50%',
          zIndex: '-1',
          transition: '.5s',
        },
        _hover: {
          background: '#FD108A',
          border: '2px solid',
          borderColor: '#FD108A',
          color: 'white',
          _before: {
            height: '0%',
          },
          _disabled: {
            background: 'white',
            border: '2px solid',
            borderColor: 'inherit',
            color: '#FD108A',
          },
        },
        _active: {
          background: '#FD108A',
          border: '2px solid',
          borderColor: '#FD108A',
          color: 'white',
          _before: {
            background: 'transparent',
          },
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export const OutlinedButton: React.FC<IButtonProps> = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Button
      px='20px'
      sx={{
        ...commonButtonStyles,
        border: '1px solid',
        borderColor: 'app.black',
        color: 'app.black',
        _hover: {},
        ...sx,
      }}
      variant='outline'
      {...rest}
    >
      {children}
    </Button>
  );
};

export const WidgetButton: React.FC<IButtonProps> = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Button
      px='20px'
      sx={{
        ...commonButtonStyles,
        borderRadius: '20px',
        borderColor: 'app.black',
        color: 'white',
        fontSize: '18px',
        fontWeight: 'bold',
        background: '#FD108A',
        _hover: {},
        width: '352px',
        ...sx,
      }}
      variant='outline'
      {...rest}
    >
      {children}
    </Button>
  );
};
