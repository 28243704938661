import React, { useEffect, useState } from 'react';

import { Box, Flex, Heading, useToast } from '@chakra-ui/react';

import { CheckboxInput } from '../../components/PaymentWidget/CheckboxInput';
import { WidgetInput } from '../../components/PaymentWidget/WidgetInput';
import { WidgetSelect } from '../../components/PaymentWidget/WidgetSelect';
import { WidgetSwitch } from '../../components/PaymentWidget/WidgetSwitch';
import { useLazyGetCoinsQuery } from '../../services/CryptoService';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  setApiKey,
  setButtonSize,
  setCompleteOnFullPaymentDetected,
  setConfirmCoinSelection,
  setFromFiat,
  setMarketRate,
  setMerchantId,
  setNotificationUrl,
  setOrderDesc,
  setOrderId,
  setQuoteExpire,
  setQuoteExpireAnimation,
  setQuoteExpiryPeriod,
  setSelectedCoins,
  setShowCoinProtocolBanner,
  setTheme,
  setTxnExpire,
  setTxnExpiryPeriod,
  setUnbranded,
  setUnbrandedTitle,
  setUnbrandedTitleOption,
  setVariant,
} from '../../store/slices/widgetSlice';
import formatOptions from '../../utils/formatOptions';

export const WidgetSettingsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const widget = useAppSelector((state) => state.widget);
  // addional params set
  const [addParam, setAddParam] = useState(false);
  const clickTimes: number[] = [];
  const { merchantId } = useAppSelector((state) => state.widget);
  const [getCoinsTrigger] = useLazyGetCoinsQuery();
  const [coins, setCoins] = useState<any>();
  const toast = useToast();

  const titleDoubleClick = () => {
    clickTimes.push(Date.now());
    if (clickTimes.length >= 5) {
      if (
        clickTimes[clickTimes.length - 1] - clickTimes[clickTimes.length - 5] <=
        10000
      ) {
        setAddParam(!addParam);
        if (localStorage.getItem('addParam')) {
          localStorage.removeItem('addParam');
        } else {
          localStorage.setItem('addParam', 'true');
        }
      }
    }
  };
  const getCoins = async () => {
    if (merchantId) {
      const { data }: any = await getCoinsTrigger({
        merchantId: merchantId,
      });
      if (data) {
        const transformedCrypto = data?.map((item: any) => {
          return {
            ...item,
            value: item.code,
            label: item.displayName,
          };
        });
        setCoins(transformedCrypto);
      } else {
        toast({
          title: 'Your merchant Id or API Key is not valid!',
          status: 'error',
          isClosable: true,
          position: 'top',
        });
      }
    }
  };
  useEffect(() => {
    if (localStorage.getItem('addParam')) {
      setAddParam(true);
    }
    getCoins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId]);

  return (
    <Flex align='center' direction='column' px='30px' w='100%'>
      <Box maxW='1340px' mt='120px' w='100%'>
        <Flex
          alignItems={{ base: 'center', lg: 'flex-start' }}
          direction={{ base: 'column', lg: 'row' }}
          gap='20px'
          justifyContent='space-around'
          pb='20px'
        >
          <Box w='fit-content'>
            <Heading
              color='#FD108A'
              onDoubleClick={() => titleDoubleClick()}
              pb='20px'
              w='fit-content'
            >
              Payment Widget Settings
            </Heading>
            <WidgetInput
              defaultValue={widget.apiKey}
              description='The payment widget should only be used with a Widget API key. If the list is empty, please go to the Integration / Api page and create one. Remember to enable your key!'
              error=''
              inputValue={(value) => dispatch(setApiKey(value))}
              title='API KEY'
            />
            <WidgetInput
              defaultValue={widget.merchantId}
              description=''
              error=''
              inputValue={(value) => dispatch(setMerchantId(value))}
              title='Merchant Id'
            />
            <WidgetInput
              defaultValue={widget.notificationUrl}
              description='This is an optional notification URL. Updates to the state of transactions will be POSTed to this endpoint.'
              error=''
              inputValue={(value) => dispatch(setNotificationUrl(value))}
              title='Notification URL'
            />
            <WidgetInput
              defaultValue={widget.orderDesc}
              description='This will provide a description for your transactions e.g “white t shirt”'
              error=''
              inputValue={(value) => dispatch(setOrderDesc(value))}
              title='Order Description'
            />
            <WidgetInput
              defaultValue={widget.orderId}
              description='This is a unique transaction reference that you provide and is mandatory. Your web page should feed this dynamically to the widget for each transaction.'
              error=''
              inputValue={(value) => dispatch(setOrderId(value))}
              title='Order ID'
            />
            <WidgetSwitch
              defaultValue={widget.showCoinProtocolBanner}
              description='For coins such as Ethereum, this controls whether to display the protocol for tokens (e.g. ERC-20, BEP-20) as a banner on the coin image. We recommend leaving this on to make coin identification easier for your users.'
              switchValue={(value) =>
                dispatch(setShowCoinProtocolBanner(value))
              }
              title='Show coin protocol banners'
            />
          </Box>
          <Box mt={{ base: '0', lg: '60px' }}>
            <WidgetSelect
              description='The fiat currency that the customer will be charged in'
              error=''
              options={formatOptions(['USD', 'EUR', 'GBP'])}
              selectValue={(value) => dispatch(setFromFiat(value))}
              title='Fiat Currency'
              value={widget.fromFiat}
            />
            <WidgetSelect
              description=''
              error=''
              options={formatOptions(['small', 'medium', 'large'])}
              selectValue={(value) => dispatch(setButtonSize(value))}
              title='Button Size'
              value={widget.buttonSize}
            />
            <WidgetSelect
              description='The theme that the widget will be displayed in'
              error=''
              options={formatOptions(['light', 'dark'])}
              selectValue={(value) => dispatch(setTheme(value))}
              title='Widget Theme'
              value={widget.theme}
            />
            <WidgetSelect
              description='Choose the type / positioning of the widget that is displayed once the button is clicked'
              error=''
              options={formatOptions(['dialog', 'popup', 'inline'])}
              selectValue={(value) => dispatch(setVariant(value))}
              title='Widget Variant'
              value={widget.variant}
            />
            <WidgetSelect
              description='Your customer has a set period of time to settle the crypto transaction. When the time expires without payment, the refresh quote button will animate according to this setting if they need to pay more.'
              error=''
              options={formatOptions(['skew', 'throb', 'shake'])}
              selectValue={(value) => dispatch(setQuoteExpireAnimation(value))}
              title='Quote Expiry Animation'
              value={widget.quoteExpireAnimation}
            />
            <WidgetSwitch
              defaultValue={widget.confirmCoinSelection}
              description='If enabled, displays a confirmation prompt after the user has selected a coin and chooses to proceed with the payment. This is enabled by default and we recommend leaving this on, so that the user confirms the coin they have chosen is the correct one.'
              switchValue={(value) => dispatch(setConfirmCoinSelection(value))}
              title='Confirm coin selection'
            />
            <WidgetSwitch
              defaultValue={widget.completeOnFullPaymentDetected}
              description="Normally, the payment widget will wait until a transaction is fully confirmed on the blockchain. This setting allows the transaction to be successfully completed as soon we detect it on the blockchain and full payment is received. If you set this option to true and the transaction doesn't ultimately succeed, you must deal with this in your payment flow."
              switchValue={(value) =>
                dispatch(setCompleteOnFullPaymentDetected(value))
              }
              title='Complete On Full Payment Detected'
            />
            <WidgetSwitch
              defaultValue={widget.marketRate}
              description='Disabled by default. If enabled, makes the payment widget transaction a market rate transaction. For non-market rate transactions, the exchange rate is adjusted to include the Remuno fee and then transaction fees are added, meaning the buyer bears the cost of the transaction. For a market rate transaction, the user is quoted the current market exchange rate for a coin and no additional fees are added. However, the Remuno fees are deducted at settlement, so the merchant bears the cost of the transaction and does not get the full amount.'
              switchValue={(value) => dispatch(setMarketRate(value))}
              title='Market rate transaction'
            />
            <WidgetSelect
              description='If enabled, narrows list of available coins'
              error=''
              isMulti
              options={coins}
              selectValue={(value) => dispatch(setSelectedCoins(value))}
              title='Selected coins'
              value={widget.selectedCoins}
            />
          </Box>
        </Flex>
        {addParam && (
          <Flex
            alignItems={{ base: 'center', lg: 'flex-start' }}
            direction={{ base: 'column', lg: 'row' }}
            gap='20px'
            justifyContent='space-around'
            pb='20px'
          >
            <Box mt='40px' w='fit-content'>
              <Heading color='#FD108A' size='md' w='fit-content'>
                Additional Parameters
              </Heading>
              <WidgetSwitch
                defaultValue={widget.unbranded}
                description='Removes Remuno branding from widget if supplied'
                switchValue={(value) => dispatch(setUnbranded(value))}
                title='Unbranded'
              />
              <CheckboxInput
                defaultChecked={widget.txnExpire}
                defaultValue={widget.txnExpiryPeriod}
                description='Number of seconds after which to force expiry of the whole transaction (used to speed up the testing process)'
                error=''
                inputValue={(value) => dispatch(setTxnExpiryPeriod(value))}
                title='Transaction Expiry Period'
                toggle={(value) => dispatch(setTxnExpire(value))}
                type='number'
              />
            </Box>
            <Box>
              <CheckboxInput
                defaultChecked={widget.unbrandedTitleOption}
                defaultValue={widget.unbrandedTitle}
                description='The top header title of the payment widget if unbranded is set true.'
                error=''
                inputValue={(value) => dispatch(setUnbrandedTitle(value))}
                title='Unbranded Title'
                toggle={(value) => dispatch(setUnbrandedTitleOption(value))}
                type='text'
              />
              <CheckboxInput
                defaultChecked={widget.quoteExpire}
                defaultValue={widget.quoteExpiryPeriod}
                description='Number of seconds after which to force expiry of the current quote (used to speed up the testing process)'
                error=''
                inputValue={(value) => dispatch(setQuoteExpiryPeriod(value))}
                title='Quote Expiry Period'
                toggle={(value) => dispatch(setQuoteExpire(value))}
                type='number'
              />
            </Box>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
