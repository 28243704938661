import * as yup from 'yup';

import {
  isEmailFormatValid,
  isLowerCase,
  haveEightCharLength,
  isUpperCase,
  isContainSpecialSymbol,
  isContainNumber,
} from '../../utils/regexp/validations';

export const signupSchema = yup
  .object({
    merchantName: yup
      .string()
      .min(5, 'Must be at least 5 characters')
      .max(100, 'Must be no more than 100 characters')
      .required(),
    userName: yup
      .string()
      .min(5, 'Must be at least 5 characters')
      .max(100, 'Must be no more than 100 characters')
      .required(),
    emailAddress: yup.string().required().concat(isEmailFormatValid),
    password: yup
      .string()
      .required()
      .concat(isLowerCase)
      .concat(isUpperCase)
      .concat(isContainNumber)
      .concat(isContainSpecialSymbol)
      .concat(haveEightCharLength),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .concat(isLowerCase)
      .concat(isUpperCase)
      .concat(isContainNumber)
      .concat(isContainSpecialSymbol)
      .concat(haveEightCharLength),
    termsAgree: yup.bool().required().oneOf([true], 'Field must be checked'),
  })
  .required();
