import React, { useState } from 'react';

import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormErrorMessage,
  Input,
  FormLabel,
  Checkbox,
  Button,
  Divider,
  useMediaQuery,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm, FormProvider } from 'react-hook-form';

import { FormContainer } from '../../components/form/form-container';
import { PasswordInput } from '../../components/form/inputs/PasswordInput';

import { signupSchema } from './validation';

// interface IFormData {
//   confirmPassword: string;
//   emailAddress: string;
//   merchantName: string;
//   password: string;
//   userName: string;
// }

export const SignUpPage: React.FC = () => {
  const [captchaValue, setCaptchaValue] = useState<string | null>('');
  const captchaKey = process.env.REACT_APP_RECAPTCHA_KEY || '';

  const [isMobile] = useMediaQuery('(max-width: 767px)');

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(signupSchema),
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = methods;

  const onReCAPTCHAChange = (value: string | null) => {
    // console.log('Captcha value:', value);
    setCaptchaValue(value);
  };

  const onSubmit = async (data: any) => {
    // eslint-disable-next-line no-console
    console.log({
      ...data,
      captchaValue,
    });
  };

  return (
    <FormContainer>
      <Box pb='20px'>
        <Heading as='h1' color='#FD108A' size={isMobile ? 'md' : 'lg'}>
          Create account
        </Heading>
        <Divider />
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            h={['85px', '100px']}
            isInvalid={Boolean(errors.merchantName)}
          >
            <FormLabel fontSize={['sm', 'md']} htmlFor='merchant-name'>
              Merchant / Company Name:
            </FormLabel>
            <Input
              id='merchant-name'
              size={isMobile ? 'sm' : 'md'}
              type='text'
              {...register('merchantName')}
            />
            <FormErrorMessage fontSize={['xs', 'sm']}>
              {errors.merchantName && errors.merchantName.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            h={['85px', '100px']}
            isInvalid={Boolean(errors.userName)}
          >
            <FormLabel fontSize={['sm', 'md']} htmlFor='user-name'>
              Your name and surname:
            </FormLabel>
            <Input
              id='user-name'
              size={isMobile ? 'sm' : 'md'}
              type='text'
              {...register('userName')}
            />
            <FormErrorMessage fontSize={['xs', 'sm']}>
              {errors.userName && errors.userName.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            h={['85px', '100px']}
            isInvalid={Boolean(errors.emailAddress)}
          >
            <FormLabel fontSize={['sm', 'md']} htmlFor='email-address'>
              Email Address:
            </FormLabel>
            <Input
              id='email-address'
              size={isMobile ? 'sm' : 'md'}
              type='email'
              {...register('emailAddress')}
            />
            <FormErrorMessage fontSize={['xs', 'sm']}>
              {errors.emailAddress && errors.emailAddress.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            h={['85px', '100px']}
            isInvalid={Boolean(errors.password)}
          >
            <FormLabel fontSize={['sm', 'md']} htmlFor='password'>
              Password:
            </FormLabel>
            <PasswordInput id='password' isMobile={isMobile} name='password' />
          </FormControl>

          <FormControl
            h={['85px', '100px']}
            isInvalid={Boolean(errors.confirmPassword)}
          >
            <FormLabel fontSize={['sm', 'md']} htmlFor='confirm-password'>
              Confirm Password:
            </FormLabel>
            <PasswordInput
              id='confirm-password'
              isMobile={isMobile}
              name='confirmPassword'
            />
          </FormControl>

          <Checkbox colorScheme='green' pb='10px' {...register('termsAgree')}>
            <Box fontSize={['sm', 'md']}>
              I agree to Remuno User Agreement and AML/KYC policy
            </Box>
          </Checkbox>

          {captchaKey && (
            <ReCAPTCHA onChange={onReCAPTCHAChange} sitekey={captchaKey} />
          )}

          <Flex justify='center' pt='20px'>
            <Button
              colorScheme='teal'
              disabled={!isValid || !captchaValue}
              type='submit'
              variant='solid'
            >
              Create Account
            </Button>
          </Flex>

          <Divider mb='5px' mt='10px' />
        </form>
      </FormProvider>
    </FormContainer>
  );
};
