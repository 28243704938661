import * as React from 'react';

import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  useNumberInput,
} from '@chakra-ui/react';
import { FaTrashAlt } from 'react-icons/fa';

import { useAppDispatch } from '../../../../store/hooks';
import {
  changeProductQuantity,
  deleteProductById,
  IProductInfo,
  IProductUpdatedInfo,
} from '../../../../store/slices/basketSlice';
import { ButtonOverrideStyles } from '../../../../styles/Button';
import { Currency, Price } from '../../../CurrencyPrice';

interface IBasketProductCard {
  product: IProductInfo & IProductUpdatedInfo;
}

export const BasketProductCardSmall: React.FC<IBasketProductCard> = ({
  product,
}) => {
  const dispatch = useAppDispatch();

  const onQuantityChange = (value: string | number) => {
    dispatch(
      changeProductQuantity({
        id: product.id,
        quantity: Number(value),
      }),
    );
  };

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: product.quantity || 1,
      min: 1,
      max: 10,
      onChange: onQuantityChange,
    });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 0',
        }}
      >
        <Box>
          <Image height='77px' src={product.mainImage} w='77px' />
        </Box>
        <Box flex={1}>
          <Heading as='h5' color='#FD108A' fontSize='15px'>
            {product.title}
          </Heading>
          <Box py='5px'>
            <Box as='span'>Size:</Box>
            <Box as='span' pl='5px'>
              43
            </Box>
          </Box>
          <Flex align='center' justifyContent='space-between'>
            <HStack maxW='320px'>
              <Button
                {...dec}
                sx={{
                  ...ButtonOverrideStyles,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '22px',
                  marginLeft: '0 !important',
                  padding: '0.25rem',
                }}
              >
                -
              </Button>
              <Input {...input} ml='0 !important' w='50px' />
              <Button
                {...inc}
                sx={{
                  ...ButtonOverrideStyles,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '22px',
                  marginLeft: '0 !important',
                  padding: '0.25rem',
                }}
              >
                +
              </Button>
            </HStack>
            <Flex align='center'>
              <Box px='3px'>x</Box>
              <Box
                sx={{
                  fontSize: '18px',
                  fontWeight: '700',
                }}
              >
                {Currency()}
                {Price(product.price)}
              </Box>
              <IconButton
                aria-label='trash'
                icon={<FaTrashAlt color='#FD108A' />}
                onClick={() => dispatch(deleteProductById(product.id))}
                sx={{ ...ButtonOverrideStyles }}
              />
            </Flex>
          </Flex>
        </Box>
      </Flex>

      <Divider />
    </>
  );
};
