import * as React from 'react';

import { Box, Flex, Heading } from '@chakra-ui/react';

import { ProductCard } from '../../../components/ProductCard';
import { TShirtsProducts } from '../../main/data';

export const TShirtsPage: React.FC = () => {
  return (
    <>
      <Heading color='#FD108A'>T-shirts</Heading>

      <Box mt='30px'>
        <b>Remuno T-shirts</b> collection: dress lightly for those summer vibes.{' '}
        <b>100% cruelty free.</b>
      </Box>

      <Flex
        justify='space-between'
        mt='30px'
        py='10px'
        sx={{
          borderTop: '1px solid rgba(160,160,160,.25)',
          borderBottom: '1px solid rgba(160,160,160,.25)',
        }}
      >
        <Flex align='center'>
          <Box mr='10px'>{TShirtsProducts.length} product(s)</Box>
        </Flex>
      </Flex>

      <Flex
        gap='30px'
        justifyContent={{ base: 'space-around', md: 'flex-start' }}
        sx={{
          mt: '30px',
          textTransform: 'uppercase',
          fontSize: '18px',
        }}
        wrap='wrap'
      >
        {TShirtsProducts.map((item) => (
          <ProductCard item={item} key={item.id} />
        ))}
      </Flex>

      <Box my='70px'>
        <b>Remuno T-shirts</b> collection: dress lightly for those summer vibes.{' '}
        <b>100% cruelty free.</b>
      </Box>
    </>
  );
};
