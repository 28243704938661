import React from 'react';

import {
  Box,
  Flex,
  Heading,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Input,
} from '@chakra-ui/react';

import { PrimaryButton } from '../../components/Buttons';

export const AddressesBlock: React.FC<any> = ({ setOpenedSection }) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton
          sx={{
            _focus: {
              boxShadow: 'none',
            },
          }}
        >
          <Box flex='1' textAlign='left'>
            <Heading color='#FD108A' my='20px' size='md'>
              2 Addresses
            </Heading>
          </Box>
        </AccordionButton>
      </h2>
      <AccordionPanel borderTop='1px rgba(160,160,160,.25) solid' px='30px'>
        <Box fontSize='14px' mb='20px'>
          {
            'The selected address will be used both as your personal address (for invoice) and as your delivery address.'
          }
        </Box>

        <Box>
          <Flex
            align={{ base: 'flex-start', md: 'center' }}
            direction={{ base: 'column', md: 'row' }}
          >
            <Box w={{ base: 'fit-content', md: '150px' }}>City</Box>

            <Box w={{ base: '100%', md: '60%' }}>
              <Input
                sx={{
                  border: 'none',
                  borderBottom: '1px solid black',
                  borderRadius: 0,
                  _focus: {
                    outline: 'none',
                  },
                }}
                type='text'
              />
            </Box>
          </Flex>

          <Flex
            align={{ base: 'flex-start', md: 'center' }}
            direction={{ base: 'column', md: 'row' }}
            mt='20px'
          >
            <Box w={{ base: 'fit-content', md: '150px' }}>Postal code</Box>

            <Box w={{ base: '100%', md: '60%' }}>
              <Input
                sx={{
                  border: 'none',
                  borderBottom: '1px solid black',
                  borderRadius: 0,
                  _focus: {
                    outline: 'none',
                  },
                }}
                type='text'
              />
            </Box>
          </Flex>

          <Flex
            align={{ base: 'flex-start', md: 'center' }}
            direction={{ base: 'column', md: 'row' }}
            mt='20px'
          >
            <Box w={{ base: 'fit-content', md: '150px' }}>Country</Box>

            <Box w={{ base: '100%', md: '60%' }}>
              <Input
                sx={{
                  border: 'none',
                  borderBottom: '1px solid black',
                  borderRadius: 0,
                  _focus: {
                    outline: 'none',
                  },
                }}
                type='text'
              />
            </Box>
          </Flex>
        </Box>

        <Flex justifyContent='center'>
          <PrimaryButton
            onClick={() => setOpenedSection(2)}
            sx={{
              w: '200px',
              mt: '50px',
            }}
          >
            Continue
          </PrimaryButton>
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
};
