import React from 'react';

import {
  Box,
  Flex,
  Heading,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Text,
  Radio,
  RadioGroup,
  Image,
} from '@chakra-ui/react';

import { PrimaryButton } from '../../components/Buttons';
import { Currency, Price } from '../../components/CurrencyPrice';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { setShippingPrice } from '../../store/slices/basketSlice';

export const ShipmentBlock: React.FC<any> = ({ setOpenedSection }) => {
  const { shippingPrice } = useAppSelector((state) => state.basket);
  const dispatch = useAppDispatch();
  return (
    <AccordionItem>
      <h2>
        <AccordionButton
          sx={{
            _focus: {
              boxShadow: 'none',
            },
          }}
        >
          <Box flex='1' textAlign='left'>
            <Heading color='#FD108A' my='20px' size='md'>
              3 Shipping method
            </Heading>
          </Box>
        </AccordionButton>
      </h2>
      <AccordionPanel borderTop='1px rgba(160,160,160,.25) solid' px='30px'>
        <Box
          sx={{
            '.chakra-radio__control': {
              borderColor: 'gray',
            },
          }}
        >
          <RadioGroup
            onChange={(value) => dispatch(setShippingPrice(Number(value)))}
            value={shippingPrice}
          >
            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap='20px'
              justifyContent='space-between'
              sx={{
                background: 'rgba(204,204,204,.15)',
                p: '20px',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Flex align='center' w='286px'>
                <Box mr={{ base: '10px', sm: '30px' }}>
                  <Radio colorScheme='facebook' value={0} />
                </Box>

                <Flex align='center' color='#FD108A'>
                  <Image mr='20px' src='/images/delivery.png' w='40px' />
                  <Text
                    display={{ base: 'none', sm: 'block' }}
                    fontWeight='bold'
                  >
                    Standard International
                  </Text>
                  <Box display={{ base: 'block', sm: 'none' }}>
                    <Text fontSize='12px' fontWeight='bold'>
                      Standard
                    </Text>
                    <Text fontSize='12px' fontWeight='bold'>
                      International
                    </Text>
                  </Box>
                </Flex>
              </Flex>
              <Flex
                direction={{ base: 'column', sm: 'row' }}
                gap={{ base: '0', sm: '50px' }}
                w='290px'
              >
                <Box>Delivery 3-5 days</Box>

                <Box ml={{ base: '0', sm: 'auto' }}>Free</Box>
              </Flex>
            </Flex>

            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap='20px'
              justifyContent='space-between'
              sx={{
                background: 'rgba(204,204,204,.15)',
                mt: '20px',
                p: '20px',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Flex align='center' w='286px'>
                <Box mr={{ base: '10px', sm: '30px' }}>
                  <Radio colorScheme='facebook' value={15} />
                </Box>

                <Flex align='center' color='#FD108A'>
                  <Image mr='20px' src='/images/delivery.png' w='40px' />
                  <Text
                    display={{ base: 'none', sm: 'block' }}
                    fontWeight='bold'
                  >
                    Express International
                  </Text>
                  <Box display={{ base: 'block', sm: 'none' }}>
                    <Text fontSize='12px' fontWeight='bold'>
                      Express
                    </Text>
                    <Text fontSize='12px' fontWeight='bold'>
                      International
                    </Text>
                  </Box>
                </Flex>
              </Flex>
              <Flex
                direction={{ base: 'column', sm: 'row' }}
                gap={{ base: '0', sm: '50px' }}
                w='290px'
              >
                <Box>Delivery 2-3 days</Box>

                <Box ml={{ base: '0', sm: 'auto' }}>
                  {Currency()}
                  {Price('15')} tax incl.
                </Box>
              </Flex>
            </Flex>
          </RadioGroup>
        </Box>

        <Flex justifyContent='center'>
          <PrimaryButton
            onClick={() => setOpenedSection(3)}
            sx={{
              w: '200px',
              mt: '50px',
            }}
          >
            Continue
          </PrimaryButton>
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
};
