import React, { useState } from 'react';

import {
  Flex,
  Box,
  Heading,
  Divider,
  useMediaQuery,
  FormLabel,
  FormControl,
  Button,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, useForm } from 'react-hook-form';

import { FormContainer } from '../../components/form/form-container';
import { PasswordInput } from '../../components/form/inputs/PasswordInput';

import { forgotPasswordSchema } from './validation';

export const ForgotPasswordPage: React.FC = () => {
  const [captchaValue, setCaptchaValue] = useState<string | null>('');
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const captchaKey = process.env.REACT_APP_RECAPTCHA_KEY || '';
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(forgotPasswordSchema),
  });
  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const onReCAPTCHAChange = (value: string | null) => {
    // console.log('Captcha value:', value);
    setCaptchaValue(value);
  };

  const onSubmit = async (data: any) => {
    // eslint-disable-next-line no-console
    console.log({
      ...data,
      captchaValue,
    });
  };

  return (
    <FormContainer>
      <Box pb='20px'>
        <Heading as='h1' size={isMobile ? 'md' : 'lg'}>
          Reset your password
        </Heading>
        <Divider />
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            h={['85px', '100px']}
            isInvalid={Boolean(errors.newPassword)}
          >
            <FormLabel fontSize={['sm', 'md']} htmlFor='newPassword'>
              New password:
            </FormLabel>
            <PasswordInput
              id='newPassword'
              isMobile={isMobile}
              name='newPassword'
            />
          </FormControl>

          <FormControl
            h={['85px', '100px']}
            isInvalid={Boolean(errors.confPassword)}
          >
            <FormLabel fontSize={['sm', 'md']} htmlFor='confPassword'>
              Confirm new password:
            </FormLabel>
            <PasswordInput
              id='confPassword'
              isMobile={isMobile}
              name='confPassword'
            />
          </FormControl>

          {captchaKey && (
            <ReCAPTCHA onChange={onReCAPTCHAChange} sitekey={captchaKey} />
          )}

          <Flex justify='center' pt='20px'>
            <Button
              colorScheme='teal'
              disabled={!isValid || !captchaValue}
              type='submit'
              variant='solid'
            >
              Reset Password
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </FormContainer>
  );
};
