import React from 'react';

import { Box, Container, Flex } from '@chakra-ui/react';

export const FormContainer: React.FC = ({ children }) => {
  return (
    <Container maxW='container.lg' p='100px 10px 50px 10px'>
      <Flex align='center' justify='center' minHeight='100%'>
        <Box
          borderRadius='lg'
          borderWidth='2px'
          boxShadow='0 5px 25px rgb(0,0,0,0.2)'
          maxW='500px'
          w='100%'
        >
          <Box px='20px' py='15px'>
            {children}
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};
