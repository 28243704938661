import React, { useEffect, useState } from 'react';

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Flex,
  Image,
  Heading,
  Button,
  useNumberInput,
  Input,
  HStack,
} from '@chakra-ui/react';
import { FaShoppingBag } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router-dom';

import { PrimaryButton } from '../../components/Buttons';
import { CarouselAlsoBuy } from '../../components/Carousels/carousel-also-buy';
import { CarouselSameCategory } from '../../components/Carousels/carousel-same-caregory';
import { Currency, Price } from '../../components/CurrencyPrice';
import { ProductAccordion } from '../../components/Product/product-accordion';
import { useAppDispatch } from '../../store/hooks';
import {
  changeProductQuantity,
  IProductInfo,
  setIsBasketOpen,
  setProductInBasket,
} from '../../store/slices/basketSlice';
import { ButtonOverrideStyles } from '../../styles/Button';
import { findProductById } from '../../utils/findProductById';
import { products, productsInSameCategory } from '../main/data';

type TProductPageParams = {
  productId: string;
};

export const ProductPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { productId } = useParams<TProductPageParams>();
  const [product, setProduct] = useState<IProductInfo | null>(null);
  const [productQuantity, setProductQuantity] = useState<number>(1);

  const onQuantityChange = (value: string | number) => {
    setProductQuantity(Number(value));
  };

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      max: 10,
      onChange: onQuantityChange,
    });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const shoesSizes = [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47];

  useEffect(() => {
    const [thisProduct] = findProductById(productId);
    setProduct(thisProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, productId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectedSize = (size: number) => {
    // eslint-disable-next-line no-console
    console.log('selectedSize', size);
  };

  const addProductInBasket = (product: IProductInfo | null) => {
    dispatch(setProductInBasket(product));
    dispatch(
      changeProductQuantity({
        id: product?.id,
        quantity: productQuantity,
      }),
    );
    dispatch(setIsBasketOpen(true));
  };

  return (
    <>
      <Container maxW='1370px' p='100px 20px 0'>
        <Breadcrumb pb='10px'>
          <BreadcrumbItem>
            <BreadcrumbLink href='/'>Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href={location.pathname} textTransform='capitalize'>
              {product?.title}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex align='center' direction={{ base: 'column', md: 'row' }}>
          <Box w={{ base: '100%', md: '50%' }}>
            <Image m='auto' maxW='600px' src={product?.mainImage} w='100%' />
          </Box>

          <Box w={{ base: '100%', md: '50%' }}>
            <Box m='auto' maxW='600px' pb='20px'>
              <Box borderBottom='1px solid rgba(160,160,160,.25)' pb='10px'>
                <Flex align='center' justify='space-between'>
                  <Heading
                    as='h1'
                    color='#FD108A'
                    fontSize='38px'
                    lineHeight='1.2'
                    pr='15px'
                    textTransform='capitalize'
                  >
                    {product?.title}
                  </Heading>
                </Flex>

                <Box
                  color='#6a6a6a'
                  fontSize='18px'
                  py='15px'
                  textTransform='capitalize'
                >
                  {product?.title}
                </Box>

                <Box py='10px'>
                  <Heading as='h3' fontSize='35px' textTransform='capitalize'>
                    {Currency()}
                    {Price(product?.price)}
                  </Heading>
                  <Box color='#2e2323' fontSize='17px'>
                    VAT included
                  </Box>
                </Box>
              </Box>

              <Box
                borderBottom='1px solid rgba(160,160,160,.25)'
                pb='30px'
                pt='30px'
              >
                <Box>
                  <Flex direction={{ base: 'column', sm: 'row' }} pt='40px'>
                    <HStack
                      justify={{ base: 'center', sm: 'flex-start' }}
                      maxW='450px'
                      w={{ base: '100%', sm: '35%' }}
                    >
                      <Button
                        {...dec}
                        sx={{
                          ...ButtonOverrideStyles,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '22px',
                          marginLeft: '0 !important',
                          padding: '0.25rem',
                        }}
                      >
                        -
                      </Button>
                      <Input {...input} ml='0 !important' w='50px' />
                      <Button
                        {...inc}
                        sx={{
                          ...ButtonOverrideStyles,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '22px',
                          marginLeft: '0 !important',
                          padding: '0.25rem',
                        }}
                      >
                        +
                      </Button>
                    </HStack>
                    <Box
                      mt={{ base: '20px', sm: 0 }}
                      w={{ base: '100%', sm: '65%' }}
                    >
                      <PrimaryButton
                        onClick={() => addProductInBasket(product)}
                        sx={{
                          width: '100%',
                          height: '100%',
                          fontSize: '17px',
                          padding: '15px 25px',
                        }}
                      >
                        <FaShoppingBag fontSize='20px' />
                        <Box as='span' pl='10px'>
                          Add to basket
                        </Box>
                      </PrimaryButton>
                    </Box>
                  </Flex>
                </Box>
              </Box>

              <ProductAccordion product={product} />
            </Box>
          </Box>
        </Flex>

        <Box pb='50px'>
          <CarouselAlsoBuy products={products} />
        </Box>

        <Box pb='50px'>
          <CarouselSameCategory products={productsInSameCategory} />
        </Box>
      </Container>
    </>
  );
};
