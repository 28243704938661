import React, { useState } from 'react';

import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Heading,
  Divider,
  useMediaQuery,
  Link,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { FormContainer } from '../../components/form/form-container';

import { RequestResetPasswordForm } from './form';

export const RequestResetPasswordPage: React.FC = () => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 767px)');

  const changeFormContent = (val: boolean) => {
    setIsButtonClicked(val);
  };

  return (
    <FormContainer>
      <Box pb='20px'>
        <Heading as='h1' size={isMobile ? 'md' : 'lg'}>
          Forgot password
        </Heading>
        <Divider />
      </Box>

      {!isButtonClicked ? (
        <RequestResetPasswordForm changeFormContent={changeFormContent} />
      ) : (
        <Box>
          <Alert mb='20px' status='success'>
            <AlertIcon />
            Check your email address and follow the link
          </Alert>
        </Box>
      )}

      <Divider />

      <Box fontSize={['sm', 'md']} pt='10px'>
        <Link
          as={RouterLink}
          color='#64acff'
          textDecoration='underline'
          to='/login'
        >
          <ArrowBackIcon /> Back to Sign In page
        </Link>
      </Box>
    </FormContainer>
  );
};
