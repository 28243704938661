if (!window.config) {
  window.config = {
    REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    REACT_APP_RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
    REACT_APP_SWAGGER_URL: process.env.REACT_APP_SWAGGER_URL,
  };
}

export const SWAGGER_URL = window.config.REACT_APP_SWAGGER_URL;

export const API_BASE_URL = window.config.REACT_APP_API_BASE_URL?.replace(
  'website-',
  '',
);

export const API_PREFIX = SWAGGER_URL?.replace(
  /https:\/\/api-?(website-)?/,
  '',
).replace(/\.remuno\.com.+/, '');

export const WIDGET_SOURCE_BASE_DOMAIN = API_PREFIX
  ? `pay-${API_PREFIX}.remuno.com`
  : 'pay.remuno.com';
