import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { cryptoApi } from '../services/CryptoService';

import basketSlice from './slices/basketSlice';
import currencySlice from './slices/currencySlice';
import widgetSlice from './slices/widgetSlice';

const rootReducer = combineReducers({
  basket: basketSlice,
  widget: widgetSlice,
  currency: currencySlice,
  [cryptoApi.reducerPath]: cryptoApi.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(cryptoApi.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
