import { createSlice } from '@reduxjs/toolkit';

const currencySlice = createSlice({
  name: 'currency',
  initialState: {
    rateGBP: 1,
    rateUSD: 1,
  },
  reducers: {
    setRate(state, action) {
      state.rateGBP = action.payload?.gbp;
      state.rateUSD = action.payload?.usd;
    },
  },
});

export default currencySlice.reducer;
export const { setRate } = currencySlice.actions;
