import { createSlice } from '@reduxjs/toolkit';

interface IMarkup {
  merchantName: string;
  totalPrice: number;
}

const widgetSlice = createSlice({
  name: 'widget',
  initialState: {
    open: true,
    merchantId: '',
    apiKey: '',
    buttonSize: 'small',
    fromFiat: 'USD',
    amount: '0',
    variant: 'dialog',
    orderId: '',
    orderDesc: '',
    theme: 'light',
    markup: <IMarkup>{
      merchantName: '',
      totalPrice: 0,
    },
    quoteExpireAnimation: 'skew',
    showCoinProtocolBanner: true,
    notificationUrl: '',
    unbranded: false,
    txnExpiryPeriod: '1200',
    quoteExpiryPeriod: '900',
    txnExpire: false,
    quoteExpire: false,
    unbrandedTitle: 'Crypto Payment Processor',
    unbrandedTitleOption: false,
    confirmCoinSelection: false,
    marketRate: false,
    completeOnFullPaymentDetected: false,
    selectedCoins: [],
  },
  reducers: {
    setButtonSize(state, action) {
      state.buttonSize = action.payload.toLowerCase();
    },
    setFromFiat(state, action) {
      state.fromFiat = action.payload;
    },
    setConfirmCoinSelection(state, action) {
      state.confirmCoinSelection = action.payload;
    },
    setCompleteOnFullPaymentDetected(state, action) {
      state.completeOnFullPaymentDetected = action.payload;
    },
    setMarketRate(state, action) {
      state.marketRate = action.payload;
    },
    setVariant(state, action) {
      state.variant = action.payload.toLowerCase();
    },
    setOrderId(state, action) {
      state.orderId = action.payload;
    },
    setOrderDesc(state, action) {
      state.orderDesc = action.payload;
    },
    setTheme(state, action) {
      state.theme = action.payload.toLowerCase();
    },
    setQuoteExpireAnimation(state, action) {
      state.quoteExpireAnimation = action.payload.toLowerCase();
    },
    setShowCoinProtocolBanner(state, action) {
      state.showCoinProtocolBanner = action.payload;
    },
    setAmount(state, action) {
      state.amount = action.payload;
    },
    setNotificationUrl(state, action) {
      if (action.payload !== '') {
        state.notificationUrl = action.payload;
      }
    },
    setMarkup(state, action) {
      state.markup = action.payload;
    },
    setMerchantId(state, action) {
      localStorage.setItem('merchantId', action.payload);
      state.merchantId = action.payload;
    },
    setApiKey(state, action) {
      localStorage.setItem('apiKey', action.payload);
      state.apiKey = action.payload;
    },
    setOpen(state, action) {
      state.open = action.payload;
    },
    setUnbranded(state, action) {
      state.unbranded = action.payload;
    },
    setTxnExpiryPeriod(state, action) {
      state.txnExpiryPeriod = action.payload;
    },
    setQuoteExpiryPeriod(state, action) {
      state.quoteExpiryPeriod = action.payload;
    },
    setTxnExpire(state, action) {
      state.txnExpire = action.payload;
    },
    setQuoteExpire(state, action) {
      state.quoteExpire = action.payload;
    },
    setUnbrandedTitle(state, action) {
      state.unbrandedTitle = action.payload;
    },
    setUnbrandedTitleOption(state, action) {
      state.unbrandedTitleOption = action.payload;
    },
    setSelectedCoins(state, action) {
      state.selectedCoins = action.payload;
    },
  },
});

export default widgetSlice.reducer;
export const {
  setApiKey,
  setMerchantId,
  setButtonSize,
  setFromFiat,
  setVariant,
  setOpen,
  setMarkup,
  setOrderId,
  setOrderDesc,
  setTheme,
  setQuoteExpireAnimation,
  setShowCoinProtocolBanner,
  setMarketRate,
  setCompleteOnFullPaymentDetected,
  setAmount,
  setNotificationUrl,
  setUnbranded,
  setQuoteExpiryPeriod,
  setTxnExpiryPeriod,
  setTxnExpire,
  setQuoteExpire,
  setConfirmCoinSelection,
  setUnbrandedTitle,
  setUnbrandedTitleOption,
  setSelectedCoins,
} = widgetSlice.actions;
