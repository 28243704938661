import React from 'react';

import {
  Box,
  Heading,
  Flex,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Container,
} from '@chakra-ui/react';
import { IoArrowBackSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/navigation';

import { BasketProductCard } from '../../components/Basket/basket-product-card';
import { BasketSummary } from '../../components/Basket/basket-summary';
import { PrimaryButton } from '../../components/Buttons';
import { CarouselAlsoBuy } from '../../components/Carousels/carousel-also-buy';
import { useAppSelector } from '../../store/hooks';
import { products } from '../main/data';

export const BasketPage: React.FC = () => {
  const navigate = useNavigate();
  const { productsInBasket } = useAppSelector((state) => state.basket);
  const basketTableHeaders = ['Product', 'Price', 'Qty', 'Total', 'Delete'];

  return (
    <>
      <Container maxW='1370px' p='115px 20px 0'>
        <Heading
          color='#FD108A'
          m={{ base: '0 0 30px 0', lg: '0 10px 30px 0' }}
          textAlign='left'
        >
          Shopping Cart
        </Heading>

        <Flex
          alignItems={{ base: 'center', xl: 'flex-start' }}
          direction={{ base: 'column', xl: 'row' }}
          gap='20px'
          justify='space-between'
        >
          <TableContainer w={{ base: '100%', xl: '70%' }}>
            <Table variant='unstyled'>
              <Thead
                sx={{
                  borderTop: '1px solid rgba(160,160,160,.25)',
                  borderBottom: '1px solid rgba(160,160,160,.25)',
                }}
              >
                <Tr>
                  {basketTableHeaders.map((header) => (
                    <Th
                      key={header}
                      sx={{
                        textAlign: 'center',
                        textTransform: 'capitalize',
                        color: '#000000',
                        fontSize: '17px',
                      }}
                    >
                      {header}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {productsInBasket.map((product) => (
                  <BasketProductCard
                    key={product.id}
                    product={product}
                    variant='large'
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          <BasketSummary />
        </Flex>

        <Flex py='50px'>
          <PrimaryButton
            onClick={() => navigate('/')}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '225px',
              height: '100%',
              fontSize: '17px',
              // borderRadius: 'none',
              color: '#ffffff',
              padding: '15px 25px',
            }}
          >
            <IoArrowBackSharp />
            <Box as='span'>Continue shopping</Box>
          </PrimaryButton>
        </Flex>

        <Box pb='50px'>
          <CarouselAlsoBuy products={products} />
        </Box>
      </Container>
    </>
  );
};
